<!--
 * @Description: 
 * @Date: 2024-03-05 18:05:29
 * @LastEditors: xiaopang
 * @LastEditTime: 2024-04-16 09:48:18
-->
<template>
  <div class='host-monitor'>
    <div class="monitor-container">
      <div class="monitor-item">
        <a-progress type="circle" :strokeWidth="12" :strokeColor="strokeColor(monitor.cpu)" :width="18" :showInfo="false" :percent="hanlderPercent(monitor.cpu)" />
        <span class="mgl4">CPU：</span>
        <span>{{ monitor.cpu }}%</span>
      </div>
      <div class="monitor-item">
        <a-progress type="circle" :strokeWidth="12" :strokeColor="strokeColor(monitor.memory)" :width="18" :showInfo="false" :percent="hanlderPercent(monitor.memory)" />
        <span class="mgl4">内存：</span>
        <span>{{ monitor.memory }}%</span>
      </div>
      <div class="monitor-item">
        <a-progress type="circle" :strokeWidth="12" :strokeColor="strokeColor(monitor.disk)" :width="18" :showInfo="false" :percent="hanlderPercent(monitor.disk)" />
        <span class="mgl4">硬盘：</span>
        <span>{{ monitor.disk }}</span>
      </div>
      <div class="monitor-item uptime">
        <span>在线：</span>
        <span>{{ monitor.uptime }}</span>
      </div>
      <div class="monitor-item long">
        <span>负载：</span>
        <span>{{ monitor.load }}</span>
      </div>
      <div class="reload-icon" @click="getMonitorData()">
        <a-icon type="reload" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: {
    treeVisible: {
      type: Boolean,
      default: true
    },
    currentFileSystemId: [String, Number]
  },
  // import引入的组件需要注入到对象中才能使用
  components: {
  },
  data () {
    // 这里存放数据
    return {
      monitor: {
        cpu: '-',
        memory: '-',
        uptime: '-',
        disk: '-',
        load: '-'
      }
    }
  },
  // 监听属性 类似于data概念
  computed: {
    ...mapState({
      groupManage: (state) => state.fileManage
    }),
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    const self = this
    self.fileSystemId = this.groupManage.showItem.id
    if (this.groupManage.showItem.treeType === 'file' && self.fileSystemId && !this.currentFileSystemId) this.getMonitorData()
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    ...mapActions("fileManage", [
      'changeShowItem',
      'changeRealEditList'
    ]),
    hanlderPercent (num) {
      if (num === '-') return 0
      if (num.includes("%")) num = num.split('%')[0]
      return Number(num)
    },
    strokeColor (num) {
      if (num.includes("%")) num = num.split('%')[0]
      let floatNum = parseFloat(num)
      if ((floatNum > '0.0' || floatNum == '0.00') && floatNum < '80.0') {
        return '#307ae8'
      } else if  (floatNum > '80.0' && floatNum < '90.0') {
        return '#f0be42'
      } else {
        return '#c73841'
      }
    },
    changeMonitor (monitor) {
      const showItem = this.groupManage.showItem
      this.changeShowItem({
        ...showItem,
        monitor: monitor
      })
      const list = this.groupManage.realEditList
      const index = list.findIndex(item => item.id === this.groupManage.showItem.id)
      if (index > 0) {
        list.splice(index, 1, {
          ...showItem,
          monitor: monitor
        });
        this.changeRealEditList(list)
      }
    },
    getMonitorData () {
      const ids = this.groupManage.showItem.id
      this.$axiosGet(global.API.getMonitorByIds(ids), { cluster_id: this.groupManage.clusterId, fields: 'all' }).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
          this.monitor = {
            cpu: res.data.data[ids]?.cpu ? parseFloat(res.data.data[ids].cpu).toFixed(2) || '-' : '-',
            memory: res.data.data[ids]?.memory ? parseFloat(res.data.data[ids].memory).toFixed(2) || '-' : '-',
            disk: (res.data.data[ids]?.disk) || '-',
            // uptime: res.data.data[ids]?.uptime.includes(':') ? res.data.data[ids].uptime : (res.data.data[ids].uptime + '天') || '-',
            uptime: (res.data.data[ids] &&  res.data.data[ids]?.uptime) ?  res.data.data[ids].uptime.includes(':') ?  res.data.data[ids].uptime : ( res.data.data[ids]?.uptime + '天') : '-',
            load: (res.data.data[ids]?.load) || '-'
          }
          this.changeMonitor(res.data.data)
        }
      });
    }
  }
}
</script>

<style lang='less' scoped>
//@import url(); 引入公共css类
.host-monitor {
  position: absolute;
  bottom: 4px;
  right: 10px;
  .monitor-container {
    display: flex;
    align-content: center;
    .monitor-item {
      display: flex;
      min-width: 110px;
      font-size: 12px;
      justify-content: center;
      align-items: center;
      /deep/ .ant-progress-inner {
        width: 28px;
        height: 28px;
      }
      /deep/ .ant-progress-circle {
        path:first-child  {
          stroke: #d4dae2!important;
        }
      }
    }
    .uptime{
      min-width: 70px;
    }
    .long {
      min-width: 120px;
    }
    .reload-icon {
        width: 20px;
        text-align: center;
        padding:0 2px;
        cursor: pointer;
        &:hover {
          background: #cfd5de;
        }
      }
  }
}
</style>
