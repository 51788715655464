var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding":"10px 10px 10px 0"}},[_c('a-form',{staticClass:"p0-32-10-32 form-content-host",attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"添加方式"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['addType', { rules: [{ required: true, message: '添加方式不能为空!' }]}]),expression:"['addType', { rules: [{ required: true, message: '添加方式不能为空!' }]}]"}],attrs:{"placeholder":"请选择添加方式"}},[_c('a-radio',{attrs:{"value":1}},[_vm._v("追加")]),_c('a-radio',{attrs:{"value":2}},[_vm._v("替换")])],1)],1),_vm._l((_vm.hostVals),function(child,childIndex){return _c('a-form-item',_vm._b({key:childIndex,staticStyle:{"margin-bottom":"0px"},attrs:{"label":childIndex === 0 ? '主机服务' : ''}},'a-form-item',childIndex === 0 ? _vm.labelCol : _vm.formItemLayoutWithOutLabel,false),[_c('a-row',{staticStyle:{"position":"relative"},attrs:{"type":"flex"}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticStyle:{"width":"97%"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                `${child.randow + 'arrayWithKey' + childIndex}`,
                {
                  validateTrigger: ['change', 'blur'],
                  initialValue: child.name,
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: `服务名称不能为空!`,
                    },
                  ],
                },
              ]),expression:"[\n                `${child.randow + 'arrayWithKey' + childIndex}`,\n                {\n                  validateTrigger: ['change', 'blur'],\n                  initialValue: child.name,\n                  rules: [\n                    {\n                      required: true,\n                      whitespace: true,\n                      message: `服务名称不能为空!`,\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"请输入服务名称"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticStyle:{"width":"97%"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                `${child.randow + 'arrayWithValue' + childIndex}`,
                {
                  validateTrigger: ['change', 'blur'],
                  initialValue: child.value,
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: `服务目录不能为空!`,
                    },
                  ],
                },
              ]),expression:"[\n                `${child.randow + 'arrayWithValue' + childIndex}`,\n                {\n                  validateTrigger: ['change', 'blur'],\n                  initialValue: child.value,\n                  rules: [\n                    {\n                      required: true,\n                      whitespace: true,\n                      message: `服务目录不能为空!`,\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":"请输入服务目录"}})],1)],1),_c('span',{staticStyle:{"position":"absolute","right":"-16px"},on:{"click":() => _vm.reduceMultiple(childIndex)}},[(_vm.hostVals.length > 1)?_c('svg-icon',{staticClass:"reduce-icon",attrs:{"icon-class":"reduce-icon"}}):_vm._e()],1)],1)],1)}),_c('a-form-item',_vm._b({staticClass:"form-multiple-item",attrs:{"label":''}},'a-form-item',_vm.formItemLayoutWithOutLabel,false),[_c('a-button',{attrs:{"type":"dashed"},on:{"click":() => _vm.addMultiple()}},[_c('a-icon',{attrs:{"type":"plus"}})],1)],1)],2),_c('div',{staticClass:"ant-modal-confirm-btns-new",staticStyle:{"margin":"0"}},[_c('a-button',{staticStyle:{"margin-right":"10px"},attrs:{"type":"primary","loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.handleSubmit.apply(null, arguments)}}},[_vm._v("确认")]),_c('a-button',{on:{"click":function($event){$event.stopPropagation();return _vm.formCancel.apply(null, arguments)}}},[_vm._v("取消")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }