/*
 * @Description:
 * @Date: 2024-01-25 15:02:31
 * @LastEditors: xiaopang
 * @LastEditTime: 2024-03-28 14:26:24
 */
import AddHostGroup from "./hostModule/components/addGroup.vue";
import AddHost from "./hostModule/components/addHost.vue";
import DeleteHostGroup from "./hostModule/components/deleteHost.vue";
const MixinHost = {
  methods: {
    convertHostTreeData(type, node) {
      this.labelsList = [];
      if (!this.cluster_id) return false;
      this.treeSpain = true;
      const params = {
        host_name: this.searchValue,
        page_size: 1000,
        cluster_id: this.cluster_id,
      };
      this.$axiosGet(global.API.getHostGroups, params).then((res) => {
        this.treeSpain = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          if (res.data.results && res.data.results.length > 0) {
            res.data.results.map((item) => {
              item.path = "/";
              item.fullpath = "/";
              const pathArr = item.path.split("/").filter((child) => child);
              let path = "";
              pathArr.map((child) => {
                path = path + "/" + child;
                parentArr.push({
                  ...item,
                  fullpath: path,
                  path: "/",
                  name: child,
                  scopedSlots: { title: "host" },
                  svgIcon: "tree-dir",
                  treeIconType: "group",
                });
              });
              const childNode = {
                name: item.name,
                isLeaf: false,
                key: "group" + item.id,
                pid: 0,
                treeType: "group",
                treeIconType: "group",
                svgIcon: "systemrootdrive",
                title: item.name,
                children: [],
                parentArr: [],
                path: "/",
                ...item,
                scopedSlots: { title: "host" },
              };
              this.treefilterData.push(childNode);
              this.treeData.push(childNode);
              this.labelsList.push(item.name);
            });
          }
          if (type === "add") this.selectedKeys = node;
          if (
            this.treeData.length > 0 &&
            type !== "add" &&
            type !== "del" &&
            type !== "searchHost"
          ) {
            let jumpFile = [];
            if (this.$route.params?.filesystemId) {
              jumpFile = this.treeData.filter(
                (item) => item.id === this.$route.params?.filesystemId
              );
            }
            if (type === "edit" && node) {
              jumpFile = this.treeData.filter((item) => item.key === node.key);
            }
            let selectFile = jumpFile.length ? jumpFile[0] : this.treeData[0];
            this.selectedKeys = [selectFile.key];
            this.pushTable(selectFile);
            setTimeout(() => {
              if (selectFile.treeType === "file")
                this.$EventBus.$emit("onGetProxyFile", selectFile, "tree");
            }, 300);
          }
          if (type === "searchHost" && this.searchValue) {
            const hostArr = this.treeData.filter(
              (item) => item.treeType === "group"
            );
            this.expandedKeys = hostArr.map((item) => item.key);
          }
        }
      });
    },
    onSelectHostTree(keys, node) {
      // 点击打开的父节点的时候将父节点选中收起来
      // && node.dataRef.treeType !== "group"
      if (node.expanded && !node.isLeaf) {
        this.expandedKeys = this.expandedKeys.filter(
          (item) => item !== node.eventKey
        );
      }
      // if ( node.dataRef.treeType === "group" && node.dataRef.children.length > 0) {
      //   this.expandedKeys = [...this.expandedKeys, node.dataRef.key];
      // }
      if (node.dataRef.treeType === "group" && !node.expanded) {
        if (node.dataRef.children.length === 0) {
          this.onLoadData(node, "group");
        } else {
          this.expandedKeys = [...this.expandedKeys, node.dataRef.key];
        }
      }
      if (!keys.length) return false;
      this.selectedKeys = keys;
      this.pushTable(node.dataRef);
    },

    addMenu(e) {
      if (e) e.stopPropagation();
      const self = this;
      let width = 630;
      let title = "添加主机";
      let content = (
        <AddHost
          clusterId={this.groupManage.clusterId}
          hasMaster={"true"}
          clearSelectIndex={() => {}}
          callBack={() => {
            self.convertTreeData("add", this.selectedKeys);
          }}
        />
      );
      this.$confirm({
        width: width,
        title: title,
        content: content,
        closable: true,
        onCancel: () => {},
        icon: () => {
          return <div />;
        },
      });
    },
    showHostPopDetail(keys, node) {
      /**
       * 操作集
       */
      this.popoverVisible = false;
      const self = this;
      let width = keys === "edit" || keys === "build" ? 560 : 400;
      let title = keys === "edit" ? "编辑主机组" : "新建主机组";
      let content =
        keys === "edit" || keys === "build" ? (
          <AddHostGroup
            detail={node}
            callBack={(param) => {
              self.convertTreeData("edit", node);
              self.editByRename(node, param.name);
            }}
            clusterId={this.cluster_id}
          />
        ) : (
          <DeleteHostGroup
            sysTypeTxt="主机组"
            detail={node}
            type="group"
            clusterId={this.cluster_id}
            refreshList={() => {
              self.convertTreeData("del");
              self.delByRemoveTree(node);
            }}
          />
        );
      this.$confirm({
        width: width,
        title:
          keys === "edit" || keys === "build"
            ? title
            : () => {
                return (
                  <div>
                    <a-icon
                      type="question-circle"
                      style="color:#2F7FD1 !important;margin-right:10px"
                    />
                    提示
                  </div>
                );
              },
        content: content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
  },
};
export { MixinHost };
