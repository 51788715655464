<!--
 * @Author: mjzhu
 * @Date: 2022-06-08 11:38:30
 * @LastEditTime: 2024-06-03 15:18:53
 * @FilePath: \awx-ui\src\pages\groupManage\mkdir.vue
-->
<template>
    <div style="padding-top: 10px">
      <a-form :label-col="labelCol" :wrapper-col="wrapperCol" :form="form" class="p0-32-10-32 form-content">
        <a-form-item label="类型">
          <a-radio-group v-decorator="['type']">
            <a-radio value="1">
              文件夹
            </a-radio>
            <a-radio value="2">
              文件
            </a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="名称">
          <a-input v-decorator="[
              'name',
              { rules: [{ required: true, message: '名称!' }] },
            ]" placeholder="请输入名称" />
        </a-form-item>
      </a-form>
      <div class="ant-modal-confirm-btns-new">
        <a-button style="margin-right: 10px" type="primary" @click.stop="handleSubmit" :loading="loading">确认</a-button>
        <a-button @click.stop="formCancel">取消</a-button>
      </div>
    </div>
  </template>
<script>
export default {
  props: {
    file: Object,
    callBack: Function,
    treeFileId: [String, Number],
    clusterId: [String, Number],
    treeFilePath: String
  },
  data() {
    return {
      editFlag: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 19 },
      },
      form: this.$form.createForm(this),
      loading: false,
    };
  },
  watch: {},
  methods: {
    checkName(rule, value, callback) {
      var reg = /[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g;
      if (reg.test(value)) {
        callback(new Error("名称中不能包含中文"));
      }
      if (/\s/g.test(value)) {
        callback(new Error("名称中不能包含空格"));
      }
      callback();
    },
    formCancel() {
      this.$destroyAll();
    },
    handleSubmit(e) {
      const _this = this;
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const params = {
            filesystem: this.treeFileId,
            cluster_id: this.clusterId
          }
          if (values.type === '2') params.isFile = true
          this.loading = true;
          const ajaxApi = values.type === '2' ? global.API.getProxyFile + this.treeFilePath + '/' + values.name + '?filesystem=' + params.filesystem + '&isFile=true' + '&cluster_id=' + this.clusterId : global.API.getProxyFile + this.treeFilePath + '/' +values.name + '?filesystem=' + params.filesystem + '&cluster_id=' + this.clusterId 
          this.$axiosJsonProxyPost(ajaxApi, {})
            .then((res) => {
              this.loading = false;
              if ([200, 201, 204, 202].includes(res.status)) {
                this.$message.success("创建成功", 2);
                this.$destroyAll();
                _this.callBack();
              } else {
                this.$message.error(res?.data?.msg || '操作失败', 2);
              }
            })
            .catch((err) => {});
        }
      });
    }
  },
  mounted() {
    this.form.getFieldsValue(["type"]);
    this.form.setFieldsValue({
      type: '1',
    });
  },
};
</script>
  <style lang="less" scoped>
  </style>
  