<!--
 * @Author: mjzhu
 * @Date: 2022-06-09 10:11:22
 * @LastEditTime: 2024-04-01 19:07:03
 * @FilePath: \awx-ui\src\pages\groupManage\fileModule\cron\index.vue
-->

<template>
  <div class="host-list">
    <a-card class="table-card">
      <div class="table-info">
        <a-row type="flex" style="margin-bottom: 12px">
          <a-col :span="clientWidth < 1700 ? 16 : 18" style="text-align: left">
            <a-button @click="delHost()">删除</a-button>
            <a-button style="margin-left: 10px" @click="onSearch">刷新</a-button>
            <a-button class="mgl10" type="primary" style="z-index: 1" @click="importCron"><svg-icon style="margin-right: 8px;" class="btn-before-icon" icon-class="host-import"></svg-icon>导入</a-button>
            <a-button class="mgl10" type="primary" style="z-index: 1" @click="exportCron"><svg-icon style="margin-right: 8px;" class="btn-before-icon" icon-class="host-import"></svg-icon>导出</a-button>
            <a-button class="mgl10" type="primary" style="z-index: 1" @click="createCron({})"><svg-icon class="btn-before-icon mgr6" icon-class="file-add"></svg-icon>添加Cron作业</a-button>
          </a-col>
          <a-col :span="clientWidth < 1700 ? 8 : 6" style="text-align: right; display: flex;">
            <a-input-search placeholder="请输入关键词查询" :value="params.name" @change="(value) => getVal(value, 'name')" @pressEnter="onSearch" @search="onSearch" />
          </a-col>
        </a-row>
        <a-table class="opt-table table-15" @change="tableChange" :row-selection="rowSelection" :columns="columns" :loading="loading" :dataSource="dataSource" :customRow="handleClickRow" rowKey="id" :pagination="pagination"></a-table>
      </div>
    </a-card>
    <a-drawer
      v-if="drawerVisible"
      title=""
      ref="drawerRef"
      class="host-cron-log"
      :class="[isFullScreen? 'hasfullScreen': '']"
      placement="bottom"
      :closable="false"
      :mask="false"
      :height="isFullScreen? '100vh' : 512"
      :visible="drawerVisible"
      :get-container="false"
      :wrap-style="{ position: 'absolute', bottom: isFullScreen ? '0px' : !drawerVisible ? '0px' : '30px' }"
      @close="onClose"
    >
      <Log :resultLoading="resultLoading" :currentRecord="currentRecord" :target="groupManage.showItem.id" :clusterId="groupManage.clusterId" :isFullScreen="isFullScreen" @closeFullScreen="closeFullScreen" @fullScreen="fullScreen" @onClose="onClose" />
    </a-drawer>
  </div>
</template>
  
<script>
import AddCron from "./components/addCron.vue";
import UploadFile from "./components/uploadFile.vue";
import DelCron from "./components/delCron.vue";
import { mapActions, mapState, mapGetters} from "vuex";
import Log from './components/log.vue'
import cronstrue from 'cronstrue/i18n';
const clientWidth = document.documentElement.clientWidth
export default {
  name: "",
  props: {
    fileCode: String,
    file: Object,
    fileSystemId: [String, Number],
    fileType: String,
    callBack: Function,
    activeKey: String,
  },
  watch: {
    activeKey: {
      handler (val) {
        if (val === 'share' && this.fileSystemId === this.groupManage.showItem.id) {
          if (!this.init) this.getCrondList()
        }
      }
    }
  },
  components: { Log },
  data() {
    return {
      init: false,
      currentRecord: {},
      resultLoading: false,
      clientWidth,
      drawerVisible: false,
      isFullScreen: false,
      setSelectedIndex: -1,
      params: {},
      pagination: {
        total: 0,
        pageSize: 15,
        current: 1,
        showTotal: (total) => `共 ${total} 条`,
      },
      dataSource: [],
      loading: false,
      selectedRowKeys: [],
      selectedRows: [],
      columns: [
        {
          title: "分",
          key: "minute",
          dataIndex: "minute",
          width: 100,
          ellipsis: true,
          customRender: (text, row, index) => {
            return <span title={text}>{text ? text : "-"}</span>;
          },
        },
        {
          title: "时",
          key: "hour",
          width: 100,
          ellipsis: true,
          dataIndex: "hour",
          customRender: (text, row, index) => {
            return <span title={text}>{text ? text : "-"}</span>;
          },
        },
        {
          title: "天",
          key: "day",
          width: 100,
          ellipsis: true,
          dataIndex: "day",
          customRender: (text, row, index) => {
            return <span title={text}>{text ? text : "-"}</span>;
          },
        },
        {
          title: "月份",
          key: "month",
          width: 100,
          ellipsis: true,
          dataIndex: "month",
          customRender: (text, row, index) => {
            return <span title={text}>{text ? text : "-"}</span>;
          },
        },
        {
          title: "工作日",
          key: "week",
          width: 100,
          ellipsis: true,
          dataIndex: "week",
          customRender: (text, row, index) => {
            return <span title={text}>{text ? text : "-"}</span>;
          },
        },
        {
          title: "命令",
          key: "command",
          ellipsis: true,
          dataIndex: "command",
          width: 300,
          customRender: (text, row, index) => {
            return <span title={text}>{text ? text : "-"}</span>;
          },
        },
        {
          title: "状态",
          key: "enabled",
          width: 110,
          dataIndex: "enabled",
          customRender: (text) => {
            return <span class={['status-text', text ? 'success-text' : 'fail-text']}>{text ? '在线' : '停用'}</span>
          },
        },
        {
          title: "调度描述",
          key: "cron",
          ellipsis: true,
          dataIndex: "cron",
          customRender: (text, row, index) => {
            return <span title={this.humanReadable(row)}>{this.humanReadable(row)}</span>;
          },
        },
        {
          title: "操作",
          key: "action",
          width: 200,
          customRender: (text, row, index) => {
            return (
              <span class="flex-container">
                <a class="btn-opt" onClick={(e) => this.createCron(row, e)}>
                  编辑
                </a>
                <a-divider type="vertical" />
                <a class="btn-opt" onClick={(e) => this.deleteCron(row, e)}>
                  删除
                </a>
                <a-divider type="vertical" />
                {row.enabled ? 
                  <a-popconfirm
                    title="确定停用吗"
                    ok-text="确定"
                    placement="left"
                    cancel-text="取消"
                    overlayClassName="master-disable"
                    onConfirm={(e) => this.enable(row, e)}
                  >
                    <a class="btn-opt" > 
                      停用
                    </a>
                  </a-popconfirm> : 
                  <a class="btn-opt" onClick={(e) => this.enable(row, e)}>
                    启用
                  </a> 
                }
                <a-divider type="vertical" />
                <a class="btn-opt" onClick={(e) => this.seeLog(row, e)}>
                  日志
                </a>
              </span>
            );
          },
        },
      ],
    };
  },
  computed: {
    ...mapState({
      groupManage: (state) => state.fileManage,
    }),
    ...mapGetters("masterMd", ["hasMaster"]),
    //复选框 需要时候再加
    rowSelection() {
      const { selectedRowKeys } = this;
      let _this = this;
      return {
        selectedRowKeys,
        onSelectAll: (selectedRowKeys, selectedRows) => {
          _this.selectedRows = selectedRows;
        },
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys;
          _this.selectedRows = selectedRows;
        },
        getCheckboxProps: (record) => {
          return {
            props: {
              name: record.name,
            },
          };
        },
      };
    }
  },
  beforeDestroy () {
  },
  methods: {
    ...mapActions("fileManage", [
      "changeRealEditList",
      "changeShowItem",
    ]),
    ...mapActions("runHistory", [
      "getHistoryList",
      "setRunningFlag",
      "setRunTab",
      "setNumAdd",
    ]),
    ...mapActions("masterMd", ["setHasMaster"]),
    creatMaster() {
      this.$destroyAll();
    },
    enable (record, e) {
      const params = {
        ...record,
        enabled: !record.enabled,
        target: this.groupManage.showItem.id + ''
      };
      this.$axiosPatch(global.API.getCrondList + '?cluster_id=' + this.groupManage.clusterId, params).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
          this.$message.success('操作成功')
          this.onSearch();
        }
      });
    },
    handleClickRow(record, index) {
      return {
        style: {
          "background-color":
            index === this.setSelectedIndex ? "#e4eefd " : "#fff",
        },
        on: {
          click: () => {
            // this.setSelectedIndex = index;
          },
        },
      };
    },
    humanReadable(record) {
      const cron = record.minute + ' ' + record.hour + ' ' + record.day + ' ' + record.month + ' ' + record.week
      // 使用 cronstrue 将 Cron 表达式转换为人类可读的形式
      let str = '-'
      try {
        str = cronstrue.toString(cron, { locale: "zh_CN", use24HourTimeFormat: true })
      } catch (err) {
        str = '-'
      }
      return str
    },
    clearSelectIndex() {
      this.setSelectedIndex = -1;
    },
    fullScreen() {
      this.isFullScreen = true;
    },
    closeFullScreen() {
      this.isFullScreen = false;
    },
    seeLog (row, e) {
      if (e) e.stopPropagation()
      this.currentRecord = row
      this.drawerVisible = true
      document.addEventListener("click", this.handleOutsideClick);
    },
    onClose () {
      this.drawerVisible = false
      this.isFullScreen = false
      this.setSelectedIndex = -1
      document.removeEventListener("click", this.handleOutsideClick);
    },
    handleOutsideClick(event) {
      // 判断点击事件是否发生在抽屉外部
      if (this.$refs.drawerRef && !this.$refs.drawerRef.$el.contains(event.target)) {
        this.onClose();
      }
    },
    delHost() {
      const self = this;
      if (this.selectedRows.length == 0) {
        this.$message.warning("请选择一条数据");
        return false;
      }
      var ids = [];
      this.selectedRows.map((item) => {
        ids.push(item.id);
      });
      let width = 400;
      let content = (
        <DelCron
          ids={ids}
          clusterId={this.groupManage.clusterId}
          hostGroupId={this.groupManage.showItem.id}
          callBack={() => {
            self.onSearch();
            self.selectedRowKeys = [];
            self.selectedRows = [];
          }}
        />
      );
      this.$confirm({
        width: width,
        title: () => {
          return (
            <div>
              <a-icon
                type="question-circle"
                style="color:#2F7FD1 !important;margin-right:10px"
              />
              提示
            </div>
          );
        },
        content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    tableChange(pagination, filter, sorter) {
      const { order, columnKey } = sorter;
      console.log(order, columnKey);
      if (this.pagination.current !== pagination.current) {
        this.pagination.current = pagination.current;
      }
      if (order) {
        if (order === "ascend") {
          this.orderBy = columnKey;
        } else {
          this.orderBy = "-" + columnKey;
        }
      } else {
        this.orderBy = "";
      }
      this.getCrondList();
    },
    edit(key) {
      const newData = [...this.dataSource]
      const target = newData.find((item) => key === item.id);
      if (target) {
        target.editable = true;
        this.setTable(newData)
      }
    },
    getVal(val, filed) {
      this.params[`${filed}`] = val.target.value;
    },
    //   查询
    onSearch() {
      this.pagination.current = 1;
      this.getCrondList();
    },
    uploadHost() {
      const self = this;
      let width = 560;
      let title = "导入";
      let content = (
        <UploadFile
          clusterId={this.groupManage.clusterId}
          callBack={() => {
            self.onSearch();
            self.$emit("getGroupList");
          }}
        />
      );
      this.$confirm({
        width: width,
        title: title,
        content: content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    importCron () {},
    exportCron () {},
    createCron(obj, e) {
      if (e) e.stopPropagation();
      const self = this;
      let width = 630;
      let title = JSON.stringify(obj) === "{}" ? "添加cron" : "编辑cron";
      let content = (
        <AddCron
          hostGroupId={this.groupManage.showItem.id}
          detail={obj}
          clusterId={this.groupManage.clusterId}
          clearSelectIndex={() => self.clearSelectIndex()}
          callBack={() => {
            self.onSearch();
          }}
        />
      );
      this.$confirm({
        width: width,
        title: title,
        content: content,
        closable: true,
        onCancel: () => {
          self.clearSelectIndex();
        },
        icon: () => {
          return <div />;
        },
      });
    },
    deleteCron(obj, e) {
      if (e) e.stopPropagation();
      const self = this;
      let width = 400;
      let ids = [obj.id]
      let content = (
        <DelCron
          ids={ids}
          clusterId={this.groupManage.clusterId}
          hostGroupId={this.groupManage.showItem.id}
          callBack={() => {
            self.getCrondList()
          }}
        />
      );
      this.$confirm({
        width: width,
        title: () => {
          return (
            <div>
              <a-icon
                type="question-circle"
                style="color:#2F7FD1 !important;margin-right:10px"
              />
              提示
            </div>
          );
        },
        content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    getCrondList() {
      this.init = true
      this.dataSource = []
      this.loading = true;
      const params = {
        page_size: 1000,
        page: 1,
        keyword: this.params.name || "",
        target: this.groupManage.showItem.id + '',
        verbose: true
      };
        // 初始化的时候获取所有主机展示
      let apiAjax = global.API.getCrondList + '?cluster_id=' + this.groupManage.clusterId
      this.$axiosGet(apiAjax, params).then((res) => {
        this.loading = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          let dataSource = []
          res.data.data.data.map(item => {
            const arr = item.crond ? item.crond.split(' ') : []
            const [minute, hour, day, month, week] = arr
            const command = arr.slice(5);
            dataSource.push({
              ...item,
              minute,
              day,
              month,
              hour,
              week,
              command: command.join(' ')
            })
          })
          this.dataSource = dataSource
        }
      });
    },
  },
  mounted() {
    const self = this
    this.sshFileSystemId = _.cloneDeep(self.fileSystemId)
  },
};
</script>

  <style lang="less" scoped>
.host-list {
  /deep/ .ant-card-body {
    padding: 12px 20px 20px 20px;
  }
  height: calc(100vh - 84px);
  background: #F7F8FA;
  .table-card {
    height: calc(100%);
    box-shadow: none;
    border: none;
  }
  .btn-opt {
    border-radius: 2px;
    font-size: 12px;
    color: #0264c8;
    letter-spacing: 0;
    font-weight: 400;
  }
  .btn-fixed {
    position: fixed;
    right: 20px;
    top: 56px;
  }
  .green-tag {
    color: white;
    width: 44px;
    display: inline-block;
    background: #307ae8;
    font-size: 12px;
    border-radius: 4px;
    padding: 2px 0;
    text-align: center;
  }
  .yellow-tag {
    color: white;
    width: 44px;
    display: inline-block;
    background: #f0be42;
    font-size: 12px;
    padding: 2px 0;
    border-radius: 4px;
    text-align: center;
  }
  .red-tag {
    color: white;
    width: 44px;
    display: inline-block;
    background: #c73841;
    font-size: 12px;
    padding: 2px 0;
    border-radius: 4px;
    text-align: center;
  }
}
/deep/.ant-input-affix-wrapper {
  input {
    height: 30px;
  }
}
/deep/.ant-input {
  border-radius: 4px;
}
/deep/.ant-card-body {
  padding-top: 12px;
  // display: flex;
  // justify-content: flex-end;
}
/deep/.w252 {
  margin-right: 0;
}
/deep/.ant-btn {
  height: 30px;
}
.bottom-drawer {
    /deep/ .ant-drawer-body {
      padding: 0 0px;
    }
    /deep/ .ant-drawer-close {
      width: 20px;
      height: 20px;
      right: 20px;
    }
    &.hasfullScreen {
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      z-index: 9999;
      padding-top: 48px;
      /deep/ .jv-code {
        padding-top: 0;
      }
    }
  }
</style>
  