<!--
 * @Description: 
 * @Date: 2024-02-27 17:10:18
 * @LastEditors: xiaopang
 * @LastEditTime: 2024-03-19 10:51:00
-->
<template>
  <a-drawer
    title=""
    class="ssh-drawer"
    :class="[isFullScreen? 'hasfullScreen': '']"
    placement="bottom"
    :closable="false"
    :mask="false"
    :height="isFullScreen? '100vh' : 320"
    :visible="drawerVisible"
    :get-container="false"
    :wrap-style="{ position: 'absolute', bottom: isFullScreen ? '0px' : !drawerVisible ? '0px' : '30px' }"
    @close="onClose"
  >
    
  <div @click.stop="stopDrawer">
    <div class="full-screen-option">
    <div :class="['file-full', 'fullscreen-exit', !isClosable && 'regular' ]" style="margin-right: 10px" @click="changeClosable">
      <svg-icon class="file-full-icon" icon-class="regular" style="font-size: 16px"></svg-icon>
    </div>
    <div v-show="!isFullScreen" class="file-full" style="margin-right: 10px" @click="fullScreen">
      <svg-icon class="file-full-icon" icon-class="file-full" style="font-size: 24px"></svg-icon>
    </div>
    <div v-show="isFullScreen" class="option fullscreen-exit" style="margin-right: 10px" @click="closeFullScreen">
      <a-icon type="fullscreen-exit" />
    </div>
    <div class="file-full" @click="onClose" style="margin-right: 0px">
      <svg-icon class="file-full-icon" icon-class="file-close" style="font-size: 24px"></svg-icon>
    </div>
  </div>
  <SshTerminal v-if="exitFlag" :drawerVisible="drawerVisible" :fileSystemId="fileManage.showItem.id" :isFullScreen="isFullScreen" :currentFullPathArr="currentFullPathArr" connectType="host_id" :activeKey="activeKey" :file="file"></SshTerminal>
  </div>
</a-drawer>
  
</template>
<script>
import { mapActions, mapState } from "vuex";
import SshTerminal from './openSSh.vue'

export default {
  components: {
    SshTerminal
  },
  computed: {
    ...mapState({
      fileManage: (state) => state.fileManage
    }),
  },
  props: { fileCode: String, isClosable: Boolean, file: Object, currentFullPathArr: Array, fileSystemId: [String, Number], fileType: String, callBack: Function, activeKey: String, connectType: { type: String, default: 'fs_id' }, drawerVisible: Boolean, isFullScreen: Boolean },
  data () {
    return {
      exitFlag: false
    }
  },
  methods: {
    ...mapActions("ssh", [
      "setDrawerVisible",
      "setIsClosable"
    ]),
    stopDrawer () {
      // this.setDrawerVisible(true)
    },
    onClose () {
      this.exitFlag = false
      this.$emit('onClose')
    },
    changeClosable () {
      this.$emit('changeClosable')
    },
    fullScreen() {
      this.$emit('changeFullScreen', true)
    },
    closeFullScreen() {
      this.$emit('changeFullScreen', false)
    },
  },
}

</script>
<style lang="less" scoped>
.ssh-drawer {
    /deep/ .ant-drawer-body {
      padding: 0 0px;
    }
    /deep/ .ant-drawer-close {
      width: 20px;
      height: 20px;
      right: 20px;
    }
    &.hasfullScreen {
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      z-index: 9999;
      padding-top: 48px;
    }
  }
  .fullscreen-exit {
    width: 24px;
    height: 24px;
    border-radius: 6px;
    border: 1px solid #cbcbd1;
    cursor: pointer;
    text-align: center;
    line-height: 22px;
  }
  .regular {
    background: #cfd5de;
  }
  .full-screen-option {
    display: flex;
    justify-content: flex-end;
    padding: 6px 10px 6px 0;
    .file-full {
      width: 24px;
      height: 24px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      float: right;
      margin-right: 24px;
    }
  }

</style>