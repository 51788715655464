<!--
 * @Author: mjzhu
 * @Date: 2022-06-13 14:04:05
 * @LastEditTime: 2024-05-24 15:37:32
 * @FilePath: \awx-ui\src\components\steps\components\formItem\formEditor.vue
-->
<template>
  <div class="edit-file">
    <Editor ref="editorFile" :propsCode="code" :editorHeight="editorHeight" :readOnly="false" theme="vs-light" :language="language" @getEditorValue="getEditorValue" />
  </div>
</template>
<script>

import Editor from "@/components/editor";

export default {
  name: "EditFile",
  components: {
    Editor
  },
  props: { fileCode: String, file: Object, fileSystemId: [String, Number], clusterId: [String, Number], fileType: String, callBack: Function },
  data() {
    return {
      code: '',
      loading: false,
      isFullScreen: false,
      editor: null
    };
  },
  watch: {
    fileCode: {
      handler (val) {
        if (val) {
          this.code = val
        }
      },
      immediate: true
    }
  },
  computed: {
    editorHeight() {
      let height = 'calc(100vh - 140px)'
      return height
    },
    language () {
      const language = global.utils.getEditorLanguage(this.file)
      return language
    }
  },
  mounted () {
    const self = this
    this.$nextTick(() => {
      self.editor = self.$refs.editorFile.monacoEditor;
      self.editor.onDidFocusEditorText(() => {
        console.log('编辑器获取焦点');
        window.addEventListener('keydown', self.handleGlobalKeyDown);
      });

      self.editor.onDidBlurEditorText(() => {
        console.log('编辑器失去焦点');
        window.removeEventListener('keydown', self.handleGlobalKeyDown);
      });
    })
  },  
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleGlobalKeyDown);
  },
  methods: {
    resetCode (code) {
      this.code = code
      this.$refs.editorFile.resetCode(code) 
    },
    fullScreen () {
      this.isFullScreen = true
      this.$emit('changeFull', 'big')
    },
    closeFullScreen () {
      this.isFullScreen = false
      this.$emit('changeFull', 'small')
    },
    getEditorValue (value) {
      this.code = value
    },
    handlerCancel () {
      this.$emit('changeFull', 'small')
      this.$emit('onCancel')
    },
    handleGlobalKeyDown(event) {
      if (event.key === 's' && (event.ctrlKey || event.metaKey)) {
        if (this.editor.hasTextFocus()) {
          event.preventDefault(); // 阻止浏览器默认的保存行为
          this.submit()
        }
      }
    },
    submit () {
      const self = this
      this.loading = true
      this.$axiosProxyPut(global.API.getProxyFile + this.file.path + "?filesystem=" + this.fileSystemId + '&cluster_id=' + this.clusterId , {
        filename: this.file.name,
        content: this.code
      }).then(res => {
        this.loading = false
        if ([200, 201, 204].includes(res.status)) {
          this.$message.success("保存成功")
          if (self.fileType === 'list') self.callBack()
          this.handlerCancel()
        }
      }).catch(res => {
        this.loading = false
        this.$message.error("文件更新失败")
      })
    }
  },
};
</script>
<style lang="less" scoped>
.edit-file {
  width: 100%;
  height: calc(100vh - 140px);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .edit-file-header {
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    border-bottom: 1px solid #D3DAE4;
    margin-bottom: 8px;
    .file-name {
      font-size: 16px;
      font-weight: 600;
    }
    .full-screen-option {
      display: flex;
      .option {
        width: 24px;
        height: 24px;
        border: 1px solid rgba(209, 210, 215, 1);
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        float: right;
      }
      .cur {
        cursor: pointer;
      }
    }
  }
  .footer {
    display: flex;
    // margin: 16px;
    justify-content: center;
  }
}
</style>