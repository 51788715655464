<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-02-08 18:25:32
 * @LastEditTime: 2024-01-28 10:57:32
 * @FilePath: \awx-ui\src\pages\operationConsole\components\bottomOpe\uploadList.vue
-->
<template>
  <div class="upload-file-list">
    <div class="file-item" v-for="(item, index) in fileList" :key="index">
      <!-- <a-spin :spinning="item.loading"> -->
        <div class="file-content">
          <div class="file-name ell" :title="item.name">{{ item.name }}</div>
          <div class="file-size">
            <div>{{ formatBytes(item.size) }}</div>
            <div v-if="item.loading" style="margin-left: 12px; width: 50%;">
              <a-progress v-if="item.status === 'success'" :percent="100" size="small" /> 
              <div class="flex-progress" v-if="item.status === 'fail'"><a-progress :percent="100"  size="small" /> <span @click="uploadFile(item, index)">重试</span></div>
              <a-progress v-if="!['fail', 'success'].includes(item.status)" :percent="item.progress" size="small" />
            </div>
          </div>
        </div>
        <!-- <a-spin :spinning="item.loading"></a-spin> -->
        <div class="file-icon">
          <svg-icon :icon-class="getIcon(item.name)" />
        </div>
        <div class="ope-file-del" @click="removeFile(item)"><svg-icon icon-class="ope-file-del" /></div>
      <!-- </a-spin> -->
    </div>
  </div>
</template>
        
<script>
export default {
  props: {
    currentTab: String,
    fileList: Array,
  },
  watch: {
    fileList: {
      handler(val) {
        console.log('fileList', val)
      },
      deep: true
    }
  },
  data() {
    return {};
  },
  methods: {
    formatBytes(size) {
      return global.utils.formatBytes(size);
    },
    getIcon(name) {
      const arr = name.split(".");
      let key = "";
      if (arr.length > 0) key = arr[arr.length - 1];
      return global.utils.mapFileTreeIcon(key);
    },
    removeFile (file) {
      this.$EventBus.$emit('removeFile', file)
    },
    uploadFile (file, index) {
      this.$emit('uploadFile', file, index)
    },
  },
  created() {},
};
</script>
        
<style lang="less" scoped>
.upload-file-list {
  height: 156px;
  overflow-y: auto;
  display: flex;
  padding: 6px;
  flex-wrap: wrap;
  .file-item {
    cursor: pointer;
    width: 24.2%;
    height: 76px;
    background: #ffffff;
    border: 1px solid rgba(218, 224, 232, 1);
    box-shadow: 0px 2px 6px 0px rgba(214, 217, 228, 0.28);
    border-radius: 4px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    margin: 6px;
    position: relative;
    .file-content {
      width: calc(100% - 50px);
      max-width: calc(100% - 50px);
    }
    .file-name {
      font-size: 16px;
      color: #242b3a;
    }
    .file-size {
      font-size: 14px;
      color: #8792a4;
      display: flex;
      width: 100%;
      align-items: center;
    }
    .file-icon {
      svg {
        font-size: 44px;
      }
    }
    .ope-file-del {
      display: none;
      position: absolute;
      top: -8px;
      right: -8px;
      cursor: pointer;
      svg {
        font-size: 16px;
        color: gray;
      }
    }
    &:hover {
      .ope-file-del { 
        display: block;
      }
    }
    .flex-progress {
      display: flex;
      align-items: center;
      /deep/ .ant-progress-line {
        width: 70%;
      }
      span {
        color: @primary-color;
        cursor: pointer;
      }
    }
  }
}
</style>