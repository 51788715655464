<!--
 * @Description: 
 * @Date: 2024-02-02 10:15:21
 * @LastEditors: xiaopang
 * @LastEditTime: 2024-04-01 19:07:21
-->
<template>
  <div class="monitor">
    <iframe v-if="hasMonitor && !installing" :src="monitorUrl" width="100%" height="100%" style="border: none; border-width: 0px"></iframe>
    <div v-if="!hasMonitor" class="soft-ware-container">
      <InstallSoftware
        ref="installSoftWareRef"
        :loading="loading"
        :appItem="appItem"
        :hostId="fileManage.showItem.id"
        :clusterId="fileManage.clusterId"
        :software="software"
        @setMonitorUrl="setMonitorUrl"
        />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import InstallSoftware from './monitorCard.vue'
export default {
  name: 'monitor',

  mounted() {
  },
  data() {
    return {
      init: false,
      hasMonitor: false,
      installing: false,
      monitorUrl: '',
      timer: null,
      appItem: {},
      software: {},
      loading: false,
    }
  },
  components: {
    InstallSoftware
  },
  computed: {
    ...mapState({
      fileManage: (state) => state.fileManage
    }),
  },
  watch: {
    'fileManage.showItem.vars': {
      handler (val) {
        if (this.activeKey === 'monitor' && this.fileSystemId === this.fileManage.showItem.id && this.fileManage.showItem.treeType === 'file') {
          if (!this.init) this.initMonitor()
        }
      }
    },  
    activeKey: {
      handler (val) {
        if (val === 'monitor' && this.fileSystemId === this.fileManage.showItem.id) {
          if (!this.init) this.initMonitor()
        }
      }
    }
  },
  beforeDestroy() {
  },
  props: { fileCode: String, file: Object, fileSystemId: [String, Number], fileType: String, callBack: Function, activeKey: String,  connectType: {type: String, default: 'fs_id'}},
  methods: {
    setMonitorUrl (installed) {
      if (installed) {
        this.hasMonitor = true
        this.monitorUrl = `http://${this.fileManage.showItem.ip}:19999/`
      } else {
        this.hasMonitor = false
        this.monitorUrl =  ''
      }

    },
    getSoftWare(type) {
      const self = this
      this.moreId = ''
      this.init = true;
      this.loading = true;
      let catagory = type ?  type === "all" ? "" : type : ""
      this.$axiosGet(global.API.getNodeSoftware + '?cluster_id=' + this.fileManage.clusterId, {
        page: this.current,
        page_size: this.pageSize,
        catagory,
        env_id: this.fileManage.showItem.id,
        keyword: 'netdata',
      }).then((res) => {
        this.loading = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          if (res.data.results.length > 0) {
            this.appItem = res.data.results[0]
            this.setMonitorUrl(this.appItem?.installed === 1)
            if (res.data.results[0].app_softwares.length > 0) this.software = res.data.results[0].app_softwares[0]
          } else {
            this.setMonitorUrl()
          }
        }
      });
    },
    initMonitor () {
      this.init = true
      this.getSoftWare()
    } 
  },
};
</script>
<style lang="less">
 .monitor {
    width: 100%;
    height: calc(100vh - 84px);
    display: flex;
    justify-content: center;
    align-items: center;
    .soft-ware-container {
      width: 40%;
      margin: 0 30%;
    }
  }
</style>