<!--
 * @Description: 
 * @Date: 2023-12-10 18:53:20
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-04-03 16:00:49
-->
<template>
  <div class='right-container'>
    <DashBoard v-if="groupManage.showItem.activeKey === 'dash-board'"  :currentFileSystemId="fileSystemId" :ref="'rightContentRef'" :activeKey="groupManage.showItem.activeKey" :treeVisible="treeVisible" :fileSystemId="fileSystemId" @seeCron="seeCron"/>
    <RightFile v-show="groupManage.showItem.activeKey === 'file'" :ref="'rightContentRef'" :activeKey="groupManage.showItem.activeKey" :treeVisible="treeVisible" :fileSystemId="fileSystemId" />
    <Terminal  v-show="groupManage.showItem.activeKey === 'terminal'" :activeKey="groupManage.showItem.activeKey" :ref="'rightContentRef'" :fileSystemId="fileSystemId"  :treeVisible="treeVisible" />
    <Monitor  v-show="groupManage.showItem.activeKey === 'monitor'" :activeKey="groupManage.showItem.activeKey" :ref="'rightContentRef'" :fileSystemId="fileSystemId"  :treeVisible="treeVisible" />
    <SoftWare  v-show="groupManage.showItem.activeKey === 'desktop'" :activeKey="groupManage.showItem.activeKey" :ref="'rightContentRef'" :fileSystemId="fileSystemId" :treeVisible="treeVisible" />
    <Cron  v-show="groupManage.showItem.activeKey === 'share'" :activeKey="groupManage.showItem.activeKey" :ref="'rightContentRef'" :fileSystemId="fileSystemId" :treeVisible="treeVisible" />
    <Blank  v-show="!['file', 'terminal', 'monitor', 'desktop', 'share', 'dash-board'].includes(groupManage.showItem.activeKey)"  :ref="'rightContentRef'" :activeKey="groupManage.showItem.activeKey" :treeVisible="treeVisible" />
    <BottomTabs  class="bottom-tabs" :bottomTabsArr="bottomTabsArr" :activeKey="groupManage.showItem.activeKey" @getActiveKey="getActiveKey" />
    <HostMonitor v-show="groupManage.showItem.treeType === 'file'" :currentFileSystemId="fileSystemId" />
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import RightFile from './file/rightFile.vue';
import Terminal from './terminal'
import BottomTabs from '@/components/commonBottomTabs'
import Blank from './blank'
import Monitor from './monitor'
import SoftWare from "./software"
import Cron from "./cron"
import DashBoard from './dashBoard'
import { mapActions, mapState } from "vuex";
import { debounce } from 'lodash';
import HostMonitor from './hostMonitor.vue'
export default {
  props: {
    treeVisible: {
      type: Boolean,
      default: true
    },
    // fileSystemId: [String, Number]
  },
  // import引入的组件需要注入到对象中才能使用
  components: {
    RightFile,
    DashBoard,
    BottomTabs,
    Terminal,
    Blank,
    Monitor,
    HostMonitor,
    SoftWare,
    Cron
  },
  data () {
    // 这里存放数据
    return {
      activeKey: 'dash-board',
      fileSystemId: '',
      bottomTabsArr: [
        {
          name: '概览',
          key: 'dash-board',
          svgIcon: 'bottom-tab-dashboard'
        },{
          name: '文件',
          key: 'file',
          svgIcon: 'bottom-tab-file'
        },{
          name: '终端',
          key: 'terminal',
          svgIcon: 'bottom-tab-terminal'
        },{
          name: '调度',
          key: 'share',
          svgIcon: 'bottom-tab-share'
        },{
          name: '软件',
          key: 'desktop',
          svgIcon: 'bottom-tab-desktop'
        },{
          name: '监控',
          key: 'monitor',
          svgIcon: 'bottom-tab-monitor'
        },
        // {
        //   name: '收藏',
        //   key: 'collect',
        //   svgIcon: 'bottom-tab-collect'
        // },
        // {
        //   name: '系统',
        //   key: 'system',
        //   svgIcon: 'bottom-tab-system'
        // },
        {
          name: '同步',
          key: 'sync',
          svgIcon: 'bottom-tab-sync'
        },
        // {
        //   name: '检索',
        //   key: 'retrieval',
        //   svgIcon: 'bottom-tab-retrieval'
        // },
        {
          name: '代理',
          key: 'proxy',
          svgIcon: 'bottom-tab-proxy'
        }]
    }
  },
  // 监听属性 类似于data概念
  computed: {
    ...mapState({
      groupManage: (state) => state.fileManage
    }),
  },
  // 监控data中的数据变化
  watch: {
    activeKey: {
      handler (val) {
      }
    },
    'groupManage.showItem.id': {
      handler(val) {
        if (this.groupManage.selectTreeFlag && this.fileSystemId === val) {
          this.activeKey = 'file'
          this.changeActiveKey()
        }
      }
    },
    'groupManage.selectTreeFlag': {
      handler(val) {
        if (this.groupManage.selectTreeFlag && this.fileSystemId === this.groupManage.showItem.id) {
          this.activeKey = 'file'
          this.changeActiveKey()
        }
      },
      immediate: true
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    const self = this
    self.fileSystemId = this.groupManage.showItem.id
    this.$EventBus.$on("setActiveKey", () => {
      this.handleSearchDebounced()
    });
    this.$EventBus.$on("setActiveFile", () => {
      this.handleFileDebounced()
    });
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    ...mapActions("fileManage", [
      "setSelectTreeFlag",
      "changeRealEditList",
      "changeShowItem"
    ]),
    changeActiveKey () {
      const showItem = this.groupManage.showItem
      this.changeShowItem({
        ...showItem,
        activeKey: this.activeKey
      })
      const list = this.groupManage.realEditList
      const index = list.findIndex(item => item.id === this.groupManage.showItem.id)
      if (index > 0) {
        list.splice(index, 1, {
          ...showItem,
          activeKey: this.activeKey
        });
        this.changeRealEditList(list)
      }
    },
    handleFileDebounced: debounce(function() {
      if (this.activeKey !== 'file') {
        this.activeKey = 'file'
        this.changeActiveKey()
      }
    }, 1000),
    handleSearchDebounced: debounce(function() {
      // 处理搜索逻辑
      this.activeKey = 'file'
      this.changeActiveKey()
    }, 1000),
    seeCron () {
      this.activeKey = 'share'
      this.changeActiveKey()
    },
    getActiveKey (key) {
      this.setSelectTreeFlag(key === 'file')
      this.activeKey = key
      this.changeActiveKey()
    }
  }
}
</script>

<style lang='less' scoped>
//@import url(); 引入公共css类
.right-container {
  position: relative;
  .bottom-tabs {
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
  }
}
</style>
