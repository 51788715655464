 <!--
 * @Author: mjzhu
 * @Date: 2022-06-13 14:04:05
 * @LastEditTime: 2024-03-20 17:20:19
 * @FilePath: \awx-ui\src\pages\groupManage\fileModule\software\softCard.vue
-->
<template>
  <div class="software-card">
    <div class="system-template">
      <a-spin :spinning="loading" v-if="softwareCard.length >0">
        <div class="flex-warp">
          <div v-for="(item, index) in softwareCard" :key="index" :class="['template-container']">
            <AppItem :class="[ moreId === item.id && 'more-container']" :activeKey="activeKey" :appItem="item" :appCate="templateCate" :moreId="moreId" :style="{height: moreId === item.id ? APPHeight(item) : '100%'}" :appType="'app'" @getMoreId='getMoreId' />
          </div>
        </div>
      </a-spin>
      <div v-else>
        <NoData />
      </div>
    </div>
    <div class="pagination">
      <a-pagination v-model="current" :page-size-options="pageSizeOptions" :total="total" show-size-changer :page-size="pageSize" :show-total="total => `共 ${total} 条`" @change="changePage" @showSizeChange="onShowSizeChange">
        <template slot="buildOptionText" slot-scope="props">
          <span>{{ props.value }}条/页</span>
        </template>
      </a-pagination>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import AppItem from "./appItem.vue";
import NoData from "@/components/noData";
export default {
  name: "SoftCard",
  components: {
    AppItem,
    NoData,
  },
  props: {
    catagory: String,
    keyWord: String,
    activeKey: String,
    templateCate: Array,
  },
  data() {
    return {
      current: 1,
      pageSize: 12,
      softwareCard: [],
      init: false,
      loading: false,
      total: 0,
      pageSizeOptions: ["12", "18", "24", "30", "60"],
      moreId: ''
    };
  },
  watch: {
    activeKey: {
      handler(val) {
        if (val === "desktop") {
          this.$nextTick(() => {
            if (!this.init) this.getSoftWare();
          });
        }
      },
    },
  },
  provide() {
    return {
      refresh: this.refresh
    }
  },
  computed: {
    ...mapState({
      groupManage: (state) => state.fileManage,
    }),
  },
  mounted() {},
  beforeUnmount() {},
  methods: {
    APPHeight (item) {
      let height = '100%'
      if (this.moreId === item.id) height = (138 +  (26 * item.app_softwares.length)) + 'px'
      return height
    },
    refresh(catagory) {
      this.current = 1;
      this.moreId = ''
      this.getSoftWare(catagory);
    },
    getMoreId (id) {
      this.moreId = id
    },
    onShowSizeChange(current, pageSize) {
      this.moreId = ''
      this.pageSize = pageSize;
      this.getSoftWare();
    },
    changePage(current, pageSize) {
      this.current = current;
      this.getSoftWare();
    },
    getSoftWare(type) {
      this.moreId = ''
      this.init = true;
      this.loading = true;
      let catagory = type ?  type === "all" ? "" : type : ""
      this.$axiosGet(global.API.getNodeSoftware + '?cluster_id=' + this.groupManage.clusterId, {
        page: this.current,
        page_size: this.pageSize,
        catagory,
        env_id: this.groupManage.showItem.id,
        keyword: this.keyWord,
      }).then((res) => {
        this.loading = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          this.softwareCard = res.data.results;
          this.total = res.data.total;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.software-card {
  position: relative;
  height: calc(100vh - 130px);
  display: flex;
  flex-direction: column;
  .system-template,
  .custom-template {
    flex: 1;
    overflow-y: auto;
    padding: 0 10px 0 0;
    &-title {
      padding-bottom: 16px;
      font-size: 14px;
      color: #1d202d;
      letter-spacing: 0;
      font-weight: 600;
    }
  }
  .flex-warp {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .template-container {
    position: relative;
    margin: 7px 16px 7px 0;
    width: calc(33.2% - 10px);
    &:nth-child(3n) {
      margin: 7px 0 7px 0;
    }
  }
  .more-container {
    position: absolute;
    z-index: 10;
  }
  .pagination {
    height: 40px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
    margin-top: 10px;
  }
  @media (max-width: 1850px) {
    .template-container {
      margin: 7px 21px 7px 0;
      width: calc(33.3% - 16px);
    &:nth-child(3n) {
      margin: 7px 0 7px 0;
    }
  }
}
}
</style>