<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-09-26 19:22:45
 * @LastEditTime: 2024-03-29 14:36:30
 * @FilePath: \awx-ui\src\pages\groupManage\fileModule\software\appItem.vue
-->
<template>
  <div :class="['app-item']">
    <div :class="['app-item-header']">
      <span class="app-logo">
        <!-- 系统图标 -->
        <img v-if="appItem.icon && !IsColorIcon(appItem.icon) && !IsNetWorkIcon(appItem.icon)" :src="appItem.icon " alt />
        <!-- 纯色图标 -->
        <div v-if="IsColorIcon(appItem.icon) && !IsNetWorkIcon(appItem.icon)" class="icon-item" :style="{
        'background': appItem.icon,
        'text-align': 'center',
        'width': '60px',
        'height': '60px',
        'border-radius': '8px',
        'margin': 'auto'
      }">
          <span :style="{
        'color': '#fff',
        'font-size': iconColorName(appItem.name).length === 1 ? '26px' : '20px',
        'width': '100%',
        'display': 'block',
        'height': '100%',
        'line-height': '60px'
      }">{{ iconColorName(appItem.name) }}</span>
        </div>
        <!-- 网络图标 -->
        <iconifyIcon v-if="appItem.icon && !IsColorIcon(appItem.icon) && IsNetWorkIcon(appItem.icon)" :icon="iconNetWorkName(appItem.icon)" style="font-size: 48px;" />
      </span>
      <div class="app-text-info">
        <div class="app-title over-flow-ellipsis-2" :style="{'font-size': appType === 'app' ? '16px': '16px'}" :title="appItem.name">{{appItem.name}}</div>
        <div v-if="appItem.description" class="app-description over-flow-ellipsis-2" :title="appItem.description">{{appItem.description}}</div>
        <div class="app-categories" v-if="!appItem.description">
          <div class="app-categories-item" v-for="(item, index) in appcategories" :key="index">
            {{ handler(item) }}
            <a-divider v-if="index !== appcategories.length - 1" type="vertical" />
          </div>
        </div>
      </div>
    </div>
    <div class="mgt26 app-softwares">
      <div class="softwares">
        <div class="label item">名称</div>
        <!-- <div class="label item">提供方</div> -->
        <div class="label item">适配平台</div>
        <div class="label item">状态</div>
        <div class="label item">操作</div>
      </div>
      <div class="softwares softwares-value" v-for="item in APPSOFTWARESFILTER" :key="item.key">
        <div class="value item  over-flow-ellipsis" :title="item.name">{{ item.name}}</div>
        <!-- <div class="value item  over-flow-ellipsis" :title="item.repo_name">{{ item.repo_name || '—'}}</div> -->
        <div class="value item over-flow-ellipsis" :title="item.platform">{{ item.platform || '—'}}</div>
        <div class="value item status">{{ item.installed ? '已安装' : '未安装'}}</div>
        <div class="value item option">
          <span style="display:inline-block" @click.stop="opt(item)">
            操作
          </span>
        </div>
      </div>
      <div v-if="hasMore" class="more-action" @click.stop="toggle">
        <a-icon :type="isOpen ? 'up' : 'down'" />
      </div>
    </div>
  </div>
</template>
<script>
import UnstallSoftware from "./unstallSoftware.vue";
import InstallSoftware from "./installSoftware.vue";
import { mapActions, mapState } from "vuex";
export default {
  props: {
    appCate: Array,
    activeKey: String,
    moreId: String,
    appItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // selectAppId: String,
    // showPopId: String,
    appType: {
      type: String,
      default: "template",
    },
  },
  inject: ["refresh"],
  data() {
    return {
      // APPSOFTWARES: [],
      isOpen: false,
      editOptions: [
        {
          name: "删除应用",
          type: "del",
        },
      ],
    };
  },
  watch: {
    moreId: {
      handler (val) {
        if (!val) {
          this.isOpen = false
        }
      }
    }
  },
  computed: {
    ...mapState({
      groupManage: (state) => state.fileManage,
    }),
    APPSOFTWARESFILTER () {
      let arr = []
      arr =  this.isOpen ? _.cloneDeep(this.appItem?.app_softwares) : _.cloneDeep(this.APPSOFTWARES)
      return arr
    },
    APPSOFTWARES () {
      let arr = _.cloneDeep(this.appItem?.app_softwares)
      if (this.appItem?.app_softwares && this.appItem?.app_softwares.length > 2) {
        arr = arr.splice(0, 2)
      } else {
        arr = this.appItem?.app_softwares
      }
      return arr
    },
    appcategories() {
      let arr = [];
      arr = this.appItem.categories ? this.appItem.categories.split(",") : [];
      return arr;
    },
    hasMore () {
      let flag = false
      flag = this.appItem?.app_softwares && this.appItem?.app_softwares.length > 2
      return flag
    }
  },
  methods: {
    toggle () {
      console.log(this.isOpen, 'this.isOpenthis.isOpenthis.isOpen')
      this.isOpen = !this.isOpen
      this.$emit('getMoreId', this.isOpen ? this.appItem.id : '')
    },
    IsNetWorkIcon(icon) {
      return global.utils.IsNetWorkIcon(icon);
    },
    iconNetWorkName (name){
      return global.utils.iconNetWorkName(name);
    },
    IsColorIcon(icon) {
      return global.utils.IsColorIcon(icon);
    },
    iconColorName(name) {
      return global.utils.iconColorName(name);
    },
    selectedTemplate(appItem) {
      if (this.appItem.id === "add") this.$emit("addApp", this.appItem);
      if (this.appItem.id !== "add") this.$emit("showPop", appItem.id);
    },
    showPop(appItem) {
      this.$emit("showPop", appItem.id);
    },
    opt (item) {
      // if (item.installed) this.unstallSoftware(item)
      // if (!item.installed) this.installSoftware(item)
      this.installSoftware(item)
    },
    editApp() {
      this.$router.push(`/app-manage/app-edit?id=${this.appItem.id}`);
    },
    handler(item) {
      let str = "";
      let obj = undefined;
      obj = this.appCate.find((child) => child.catagory === item);
      str = obj ? obj.name : item;
      return str;
    },

    installSoftware(item) {
      const self = this;
      let width = 720;
      let content = (
        <InstallSoftware
          appItem={this.appItem}
          appCate={this.appCate}
          hostId={this.groupManage.showItem.id}
          clusterId={this.groupManage.clusterId}
          software={item}
          activeKey={this.activeKey}
          callBack={() => {
            self.refresh()
          }}
        />
      );
      this.$confirm({
        width: width,
        title: () => {
          return (
            <div>
              操作面板
            </div>
          );
        },
        content,
        closable: true,
        onCancel: () => {
          this.refresh() 
        },
        icon: () => {
          return <div />;
        },
      });
    },
    unstallSoftware(item) {
      const self = this;
      let width = 540;
      let content = (
        <UnstallSoftware
          detail={this.appItem}
          software={item}
          callBack={() => {
            self.unstall(item);
          }}
        />
      );
      this.$confirm({
        width: width,
        title: () => {
          return (
            <div>
              卸载 {item.name}
            </div>
          );
        },
        content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    unstall(item) {
      const self = this;
      this.$axiosJsonPost(global.API.unstallSoftware(item.id), {
        target: this.groupManage.showItem.id + '',
        conn_timeout: 300,
        exec_timeout: 300,
        verbose: true
      })
        .then((res) => {
          if ([200, 201, 204, 202].includes(res.status)) {
            this.$message.success("卸载成功", 2);
            self.refresh()
          }
        })
        .catch((err) => {});
    },
  },
  mounted() {
  },
  beforeDestroy() {},
};
</script>
<style lang="less" scoped>
.app-item {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 194px;
  box-shadow: 0px 2px 16px 0px rgba(65, 81, 107, 0.1);
  background: #fff;
  padding: 19px 20px 19px 20px;
  color: #adb0b8;
  border:  1px solid rgba(207,213,222,1);
  border-radius: 6px;
  &-header {
    display: flex;
  }
  .collect {
    position: absolute;
    top: -10px;
    right: -4px;
    font-size: 60px;
    z-index: 10;
  }
  .app-text-info {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .app-logo {
    height: 60px;
    margin: 0px 18px 0 0;
    width: 60px;
    min-width: 60px;
    line-height: 40px;
    background: #fff; // 这个是图标的颜色定义
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3px 10px 0px rgba(66, 82, 108, 0.2);
    border-radius: 8px;
    display: flex;
    img {
      width: 60px;
      border-radius: 8px;
      margin: auto;
    }
  }
  .app-title {
    flex: 1;
    line-height: 24px;
    font-weight: 600;
    font-size: 15px;
    color: #1d202d;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 24px;
  }
  .app-description {
    line-height: 20px;
    font-size: 12px;
    // padding-right: 20px;
    color: #7d838e;
    letter-spacing: 0;
    font-weight: 400;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
  }
  .app-categories {
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &-item {
      margin-top: 6px;
      font-size: 12px;
      color: #7d838e;
      display: inline-block;
    }
  }
  // 选中的这个模板
  &-selected {
    background: #f4faff;
    border: 1px solid rgba(57, 116, 244, 1);
    box-shadow: 0px 2px 10px 0px rgba(66, 82, 108, 0.2);
    border-radius: 6px;
  }
  .mgt26 {
    margin-top: 22px;
  }
  .more-action {
    cursor: pointer;
    position: absolute;
    right: 0px;
    bottom: 2px;
    width: 16px;
    height: 16px;
    background: #FFFFFF;
    border: 1px solid rgba(226,230,235,1);
    border-radius: 50%;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .app-softwares {
    position: relative;
    .softwares {
      display: flex;
      align-items: center;
      width: 100%;
      .item {
        width: 25%;
        padding-left: 10px;
        font-size: 12px;
        border-right: 1px solid rgb(226, 230, 235);
        &:last-child {
          border-right: none;
        }
        &:first-child {
          // padding-left: 0;
        }
      }
      .label {
        color: #7f838e;
        padding-bottom: 2px;
      }
      .value {
        min-height: 24px;
        color: #34343c;
        line-height: 24px;
      }
      .status {
        color: #07C060;
      }
      .option {
        cursor: pointer;
        color: #3974F4;
      }
    }
  }
  .pop-options {
    position: absolute;
    width: 100%;
    left: 0px;
    top: 100px;
    z-index: 1;
    width: 100%;
    border-radius: 6px;
    background-image: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
    box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.2);
    .option-item {
      width: 100%;
      height: 32px;
      line-height: 32px;
      padding-left: 24px;
      font-size: 14px;
      color: #34343c;
      &:hover {
        background: #f3f6f9;
      }
    }
  }
}
.add-item {
  justify-content: center;
  .app-logo {
    background: #f2f5f9;
  }
}
.softwares-value {
  &:hover {
    background: #f0f7ff;
  }
}
</style>