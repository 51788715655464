<!--
 * @Author: mjzhu
 * @Date: 2022-06-13 14:04:05
 * @LastEditTime: 2024-03-19 16:58:57
 * @FilePath: \awx-ui\src\pages\groupManage\fileModule\cron\components\log.vue
-->
<template>
  <div class="run-task-page" style="position: relative;">
    <div class="file-center-footer">
      <div class="center-footer-options">
        <div class="center-footer-option full-screen-option">
          <div class="file-full" @click="onClose" style="margin-right: 0px">
            <svg-icon class="file-full-icon" icon-class="file-close" style="font-size: 24px"></svg-icon>
          </div>
          <div v-show="!isFullScreen" class="file-full" style="margin-right: 10px" @click="fullScreen">
            <svg-icon class="file-full-icon" icon-class="file-full" style="font-size: 24px"></svg-icon>
          </div>
          <div v-show="isFullScreen" class="option" style="margin-right: 10px" @click="closeFullScreen">
            <a-icon type="fullscreen-exit" />
          </div>
        </div>
      </div>
      <div class="center-footer-log">
        <log-content :log="log" :editorHeight="editorHeight" />
      </div>
    </div>
  </div>
</template>
<script>
import logContent from './logContent.vue';
export default {
  components: {logContent},
  props: {
    currentRecord: Object,
    resultLoading: Boolean,
    isFullScreen:{
      type: Boolean,
      default: false
    },
    target: Number,
    clusterId: String,
  },
  data() {
    return {
      log: ''
    };
  },
  computed: {
    editorHeight() {
      let height = document.body.clientHeight;
      if (this.isFullScreen) return `${height - 50}px`
      return `470px`;
    },
  },
  watch: {
    currentRecord: {
      handler (val) {
        this.getCronLog(val)
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {},
  methods: {
    fullScreen() {
      this.$emit('fullScreen')
    },
    onClose () {
      this.$emit('onClose')
    },
    closeFullScreen() {
      this.$emit('closeFullScreen')
    },
    getCronLog (val) {
      this.$axiosGet(global.API.getCronLog(this.currentRecord.id), {
        target: this.target + '',
        cluster_id: this.clusterId,
        format: 'txt'
      }).then((res) => {
        this.loading = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          this.log = res.data
        }
      });
    },
  },
};
</script>
  <style lang="less" scoped>
.run-task-page {
  .file-center-footer {
    .center-footer-options {
      height: 40px;
      display: flex;
      align-items: center;

      .center-footer-option {
        position: relative;
        & + .center-footer-option {
          margin-left: 40px;

          &::before {
            content: "";
            position: absolute;
            left: -20px;
            width: 1px;
            height: 18px;
            background: #e2e4e5;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
      .full-screen-option {
        flex: 1;
        .option {
          width: 24px;
          height: 24px;
          border: 1px solid rgba(209, 210, 215, 1);
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          float: right;
          margin-right: 24px;
        }
        .file-full {
          width: 24px;
          height: 24px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          float: right;
          margin-right: 24px;
        }
        .file-full-icon:hover {
          color: red;
        }
      }
    }

    .center-footer-log {
      .log-option {
        width: 24px;
        height: 24px;
        border: 1px solid rgba(209, 210, 215, 1);
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      &.fullScreen {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 9999;
        padding-top: 48px;
        /deep/ .jv-code {
          padding-top: 0;
        }
      }
    }
  }
}
</style>