 <!--
 * @Author: mjzhu
 * @Date: 2022-06-13 14:04:05
 * @LastEditTime: 2024-05-28 14:23:06
 * @FilePath: \awx-ui\src\pages\groupManage\fileModule\software\index.vue
-->
<template>
  <div class="software">
    <div class="flex-bewteen-container">
      <a-radio-group v-model="catagory" @change="onChange">
        <a-radio-button v-for="item in templateCate" :value="item.catagory" :key="item.catagory">{{item.name}}</a-radio-button>
      </a-radio-group>
      <div class="form-search">
        <a-input-search style="width: 200px" placeholder="请输入关键字查询" v-model="keyWord" @pressEnter="refresh" />
        <a-button class="add-param mgl8" type="primary" @click="refresh">刷新</a-button>
      </div>
    </div>
    <SoftCard ref=softCard :catagory="catagory" :templateCate="templateCate" :keyWord="keyWord" :activeKey="activeKey" />
  </div>


</template>
<script>
import { mapActions, mapState } from "vuex";
import SoftCard from './softCard'
export default {
  name: "SoftWare",
  components: {
    SoftCard,
  },
  props: {
    fileCode: String,
    file: Object,
    fileSystemId: [String, Number],
    fileType: String,
    callBack: Function,
    activeKey: String,
  },
  data() {
    return {
      catagory: "all",
      templateCate: [],
      keyWord: "",
      init: false,
      loading: false
    };
  },
  watch: {
    activeKey: {
      handler (val) {
        if (val === 'desktop') {
          if (!this.init) this.getCatagories()
        }
      }
    }
  },
  computed: {
    ...mapState({
      groupManage: (state) => state.fileManage,
    }),
  },
  mounted() {
  },
  beforeUnmount() {},
  methods: {
    refresh() {
      this.$refs.softCard.refresh()
    },
    getCatagories() {
      this.init = true
      this.$axiosGet(global.API.getAppCatagories, {
        page: 1,
        pageSize: 1000,
        kind: -1,
        cluster_id: this.groupManage.clusterId
      }).then((res) => {
        this.loading = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          this.templateCate = [{ catagory: "all", name: "全部软件" }];
          res.data.results.map((item) => {
            this.templateCate.push(item);
          });
        }
      });
    },
    onChange(e) {
      console.log(`checked = ${e.target.value}`);
      this.catagory = e.target.value
      this.$refs.softCard.refresh(this.catagory)
    },
  },
};
</script>
<style lang="less" scoped>
.software {
  width: 100%;
  height: calc(100vh - 84px);

  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 10px 0 0 16px;
  .flex-bewteen-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px 0 0;
    .form-search {
      display: flex;
    }
  }
}
</style>