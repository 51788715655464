<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2024-03-12 11:18:31
 * @LastEditTime: 2024-03-12 11:18:40
 * @FilePath: \awx-ui\src\pages\groupManage\fileModule\cron\components\uploadFile.vue
-->
<template>
  <div></div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  components: {
  },
  mounted() {
  },
  methods: {
  },
}

</script>
<style scoped lang='less'>
</style>