<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-11-24 16:07:29
 * @LastEditTime: 2024-03-18 17:33:48
 * @FilePath: \awx-ui\src\components\createTask\components\log.vue
-->
<template>
  <div class="log-editor">
    <editor id="editor" ref="formEditor" class="log-editor-content" :editorHeight="editorHeight" :propsCode="log" :readOnly="true" theme="vs-dark" language="json" />
  </div>
</template>

<script>
import editor from "@/components/editor";

export default {
  components: {
    editor
  },
  props: {
    log: String,
    editorHeight: String
  },
  data() {
    return {
      step: {
        name: "",
        log: "",
      },
    };
  },
  watch: {
    log: {
      handler(val) {
        this.$refs.formEditor.resetCode(val)
      },
      immediate: false
    }
  },
  computed: {
  },
  mounted() {
   
  },
  methods: {
      
  },
  beforeDestroy() {
  },
};
</script>
<style scoped lang='less'>
.log-editor {
  &-content {
    position: relative; 
    /deep/ .rename-box {
      display: none;
    }
  }
}
</style>