var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding":"10px 10px 10px 0"}},[_c('a-form',{staticClass:"p12-32-10-32 form-content-host",attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"主机分组"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'groupId',
          { rules: [{ required: true, message: '主机组不能为空!' }] },
        ]),expression:"[\n          'groupId',\n          { rules: [{ required: true, message: '主机组不能为空!' }] },\n        ]"}],attrs:{"show-search":true,"not-found-content":null,"filter-option":true,"allowClear":true,"placeholder":"请选择或输入主机组"},on:{"search":_vm.handleSearch,"blur":_vm.handleBlur,"change":_vm.handleChange}},_vm._l((_vm.hostGroupList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])}),1)],1),_c('a-form-item',{attrs:{"label":"主机ip"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'ip',
          { rules: [{ required: true, message: 'IP地址不能为空!' }] },
        ]),expression:"[\n          'ip',\n          { rules: [{ required: true, message: 'IP地址不能为空!' }] },\n        ]"}],attrs:{"placeholder":"请输入IP地址"},on:{"change":_vm.changeIp}})],1),_c('a-form-item',{attrs:{"label":"主机名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          { rules: [{ required: true, message: '主机名不能为空!' }] },
        ]),expression:"[\n          'name',\n          { rules: [{ required: true, message: '主机名不能为空!' }] },\n        ]"}],attrs:{"disabled":_vm.isDisabled,"placeholder":"请输入主机名"}})],1),_c('a-form-item',{attrs:{"label":"管理节点"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'master',
          {
            rules: [
              {
                required: true,
                message: '请选择管理节点!',
              },
            ],
          },
        ]),expression:"[\n          'master',\n          {\n            rules: [\n              {\n                required: true,\n                message: '请选择管理节点!',\n              },\n            ],\n          },\n        ]"}],style:({ width: _vm.pageFalse === 'true' ? '100%' : '94%' })},_vm._l((_vm.masterSelectList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1),(_vm.pageFalse === 'false')?_c('a-icon',{staticStyle:{"margin-left":"8px","cursor":"pointer"},attrs:{"type":"plus"},on:{"click":_vm.openDialog}}):_vm._e()],1),_c('a-form-item',{staticStyle:{"margin-bottom":"0px"},attrs:{"label":"操作系统"}},[_c('a-row',{staticStyle:{"position":"relative"},attrs:{"type":"flex"}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticStyle:{"width":"97%"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'osType',
                { rules: [{ required: true, message: '操作系统不能为空!' }] },
              ]),expression:"[\n                'osType',\n                { rules: [{ required: true, message: '操作系统不能为空!' }] },\n              ]"}],attrs:{"placeholder":"请选择操作系统"}},_vm._l((_vm.osTypeList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value}},[_vm._v(_vm._s(item.label))])}),1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"mgl6",staticStyle:{"width":"97%"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'port',
              { rules: [{ required: true, message: '端口号不能为空!' }] },
            ]),expression:"[\n              'port',\n              { rules: [{ required: true, message: '端口号不能为空!' }] },\n            ]"}],attrs:{"placeholder":"请输入端口号，如：22"}})],1)],1)],1)],1),_c('a-form-item',{attrs:{"label":"用户名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'username',
          { rules: [{ required: true, message: '用户名不能为空!' }] },
        ]),expression:"[\n          'username',\n          { rules: [{ required: true, message: '用户名不能为空!' }] },\n        ]"}],attrs:{"type":"username","placeholder":"请输入用户名"}})],1),_c('a-form-item',{attrs:{"label":"认证方式"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'auth_type',
          { rules: [{ required: true, message: '认证方式不能为空!' }] },
        ]),expression:"[\n          'auth_type',\n          { rules: [{ required: true, message: '认证方式不能为空!' }] },\n        ]"}],attrs:{"placeholder":"请选择认证方式"}},[_c('a-radio-button',{attrs:{"value":"password"}},[_vm._v("密码")]),_c('a-radio-button',{attrs:{"value":"private_key"}},[_vm._v("密钥")])],1)],1),(!_vm.showAuthFlag)?_c('a-form-item',{attrs:{"label":"用户密码"}},[(!_vm.isShow)?_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'password',
          { rules: [{ required: !_vm.editFlag, message: '密码不能为空!' }] },
        ]),expression:"[\n          'password',\n          { rules: [{ required: !editFlag, message: '密码不能为空!' }] },\n        ]"}],attrs:{"type":"password","placeholder":"请输入密码"}}):_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'password',
          { rules: [{ required: !_vm.editFlag, message: '密码不能为空!' }] },
        ]),expression:"[\n          'password',\n          { rules: [{ required: !editFlag, message: '密码不能为空!' }] },\n        ]"}],attrs:{"placeholder":"请输入密码"}}),_c('span',{staticStyle:{"position":"absolute","cursor":"pointer","right":"14px","top":"-6px"},on:{"click":function($event){$event.stopPropagation();_vm.isShow = !_vm.isShow}}},[_c('svg-icon',{staticStyle:{"font-size":"18px"},attrs:{"icon-class":!_vm.isShow ? 'eye-invisible' : 'eye'}})],1)],1):_vm._e(),(_vm.showAuthFlag)?_c('a-form-item',{attrs:{"label":"密钥"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'private_key',
          { rules: [{ required: true, message: '密钥不能为空!' }] },
        ]),expression:"[\n          'private_key',\n          { rules: [{ required: true, message: '密钥不能为空!' }] },\n        ]"}],attrs:{"placeholder":"请输入密钥"}})],1):_vm._e(),_c('a-form-item',_vm._b({staticClass:"form-multiple-item",attrs:{"label":''}},'a-form-item',_vm.formItemLayoutWithOutLabel,false)),_c('a-form-item',{attrs:{"label":"主机描述"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'desc',
          { rules: [{ required: false, message: '描述不能为空!' }] },
        ]),expression:"[\n          'desc',\n          { rules: [{ required: false, message: '描述不能为空!' }] },\n        ]"}],attrs:{"type":"textarea","placeholder":"请输入描述"}})],1)],1),_c('div',{staticClass:"ant-modal-confirm-btns-new",staticStyle:{"margin":"0"}},[_c('a-button',{on:{"click":function($event){$event.stopPropagation();return _vm.formCancel.apply(null, arguments)}}},[_vm._v("取消")]),_c('a-button',{staticClass:"mgl10",attrs:{"type":"primary","loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.handleSubmit.apply(null, arguments)}}},[_vm._v("保存")])],1),(_vm.visible)?_c('a-modal',{attrs:{"title":"配置Master"},on:{"ok":_vm.handleOk},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('configMaster',{on:{"creatMaster":_vm.creatMaster,"refreshList":_vm.getMaster}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }