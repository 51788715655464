<!--
 * @Description: 
 * @Date: 2023-12-10 18:53:20
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-04-03 15:48:30
-->
<template>
  <div class='group-container'>
    <File v-show="groupManage.showItem.treeType === 'file'" ref="fileRef" :file="file" :treeVisible="treeVisible" :fileSystemId="fileSystemId" />
    <Host v-show="groupManage.showItem.treeType !== 'file'" ref="hostRef" :treeVisible="treeVisible" :hostGroupId="groupManage.showItem.id" />
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import File from './fileModule/index.vue'
import Host from './hostModule/index.vue'
import { mapActions, mapState } from "vuex";
import { debounce } from 'lodash';

export default {
  props: {
    file: {
      type: Object,
      default: () => {
        return {};
      },
    },
    treeVisible: {
      type: Boolean,
      default: true
    }
  },
  // import引入的组件需要注入到对象中才能使用
  components: {
    File,
    Host
  },
  data () {
    // 这里存放数据
    return {
      activeKey: 'file',
      fileSystemId: '',
    }
  },
  // 监听属性 类似于data概念
  computed: {
    ...mapState({
      groupManage: (state) => state.fileManage
    }),
  },
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    const self = this
    self.fileSystemId = this.groupManage.showItem.id
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
  }
}
</script>

<style lang='less' scoped>
//@import url(); 引入公共css类
.group-container {
  position: relative;
  .bottom-tabs {
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
  }
}
</style>
