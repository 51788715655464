<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-02-08 18:25:32
 * @LastEditTime: 2024-06-07 09:59:58
 * @FilePath: \awx-ui\src\pages\groupManage\fileModule\file\uploadFile.vue
-->
<template>
  <span>
    <a-upload
      name="file"
      ref="upload"
      :multiple="true"
      :showUploadList="false"
      :remove="handleRemove"
      :beforeUpload="beforeUpload"
    >
      <div class="upload-item">
        <svg-icon
          style="margin-right: 4px"
          icon-class="file-upload"
        />上传
      </div>
    </a-upload>
    <!-- 隐藏的 input 元素 -->
    <input
      id="fileUpload-custom"
      ref="fileInput"
      type="file"
      :accept="fileType"
      multiple
      style="display: none"
      @change="handleChange"
    />
  </span>
</template>
      
<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";
import { guid } from '@/utils/axios-peer'
import { uploadFilesConcurrently, uploadPeerFile } from '@/utils/uploadChannel'
export default {
  props: {
    file: Object,
    treeFileId: [String, Number],
    treeFilePath: String,
    clusterId: [String, Number],
    fileRoute: Array
  },
  data () {
    return {
      fileList: [],
      headers: {
        authorization: "authorization-text",
        Authorization: sessionStorage.getItem("token"),
      },
      fileType:
        ".xls, .xlsx, .csv, .zip, .txt, .doc, .docx, .dotx, .dot, .pot, .ppt, .pptx, .json, .js, .html, .css, .less, .scss, .log .jar, .lock, .log, .vue, .java, .jpg, .png, .jpeg, .svg, .gif, .pdf, .mp4, .mp3",
    };
  },
  computed: {
    ...mapState({
      fileManage: (state) => state.fileManage,
    }),
  },
  watch: {
    fileList: {
      handler (val) {
        this.$emit("getFileList", val);
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("fileManage", [
      "changeShowFileProgress",
      "changeUploadFileList",
      "changeUpload"
    ]),
    clearFileList () {
      this.fileList = []
    },
    handleRemove (file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload (file) {
      if (file.size > 300 * 1024 * 1000) {
        this.$message.error("文件上传最大仅支持300M");
        return;
      }
      file.status = "notUpload";
      file.loading = false;
      file.progress = 0
      this.fileList.push(file);
      this.hostsImport(file)
      return false;
    },
    handleChange (file) {
      const fileList = file.target.files
      // for (let i = 0; i < fileList.length; i++) {
      //   const file = fileList[i]; 
      //   if (file.size > 300 * 1024 * 1000) {
      //     this.$message.error("文件上传最大仅支持300M");
      //     return;
      //   }
      //   file.status = "notUpload";
      //   file.loading = false;
      //   file.progress = 0
      //   this.hostsImport(file)
      // }
      for (let i = 0; i < fileList.length; i++) {
        // 为每个文件添加一个基于时间戳的唯一标识
        fileList[i].fileId = guid();
      }

      this.changeShowFileProgress(true)
      this.changeUpload('upload')
      const stateUploadList = this.fileManage.uploadFileList
      let uploadList = []
      Array.from(fileList).map((file) => {
        uploadList.unshift({
          fileId: file.fileId,
          name: file.name,
          size: file.size,
          lastModified: file.lastModified,
          type: file.type,
          progress: 0
        })
      })
      this.changeUploadFileList(uploadList.concat(stateUploadList))
      const url = global.API.getProxyFile + this.treeFilePath + '?filesystem=' + this.treeFileId + '&cluster_id=' + this.clusterId
      uploadFilesConcurrently(fileList, url, () => { this.$emit('refreshRoute', 'upload', this.fileRoute, this.treeFileId) }, 3).then(results => {
        console.log("所有文件上传结果:", results);
        // this.$emit('refreshRoute', 'upload', this.fileRoute, this.treeFileId) 
      })
        .catch(error => {
          console.error("上传过程中遇到错误:");
        });
      this.$refs.fileInput.value = ''
    },
    hostsImport (file) {
      const self = this
      const params = {
        filesystem: this.treeFileId
      }
      // changeShowFileProgress
      const formData = new FormData();
      formData.append("file", file);
      self.changeShowFileProgress(true)
      self.changeUpload('upload')
      const fileId = guid()
      uploadPeerFile(global.API.getProxyFile + this.treeFilePath + '?filesystem=' + params.filesystem + '&cluster_id=' + this.clusterId, file, {
        onUploadProgress: function (progressEvent) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          console.log(`文件 ${file.name} 上传进度: ${percentCompleted}%`);
          const uploadist = self.fileManage.uploadFileList
          const index = uploadist.findIndex(item => item.fileId === fileId)
          if (index === -1) {
            uploadist.unshift({
              name: file.name,
              size: file.size,
              type: file.type,
              progress: percentCompleted,
              fileId
            })
          } else {
            uploadist.splice(index, 1, {
              ...uploadist[index],
              progress: percentCompleted,
            })
          }
          self.changeUploadFileList(uploadist)
        },
        rtcscope: 'file'
      },
        () => { this.$emit('refreshRoute', 'upload', this.fileRoute, this.treeFileId) })
      // this.$axiosPutFormData(
      //   global.API.getProxyFile + this.treeFilePath + '?filesystem=' + params.filesystem + '&cluster_id=' +  this.clusterId,
      //   formData,
      //   {
      //     onUploadProgress: function(progressEvent) {
      //       const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      //       const uploadist = self.fileManage.uploadFileList
      //       const index = uploadist.findIndex(item => item.fileId === fileId)
      //       if (index === -1) {
      //         uploadist.unshift({
      //           name: file.name,
      //           size: file.size,
      //           type: file.type,
      //           progress: percentCompleted,
      //           fileId
      //         })
      //       } else {
      //         uploadist.splice(index, 1, {
      //           ...uploadist[index],
      //           progress: percentCompleted,
      //         })
      //       }
      //       self.changeUploadFileList(uploadist)
      //     }
      //   }
      // )
      //   .then((res) => {
      //     if ([200, 201, 202, 204].includes(res.status)) {
      //       setTimeout(() => {
      //         this.$emit('refreshRoute', 'upload', this.fileRoute, this.treeFileId)
      //       }, 500)
      //     } else {
      //       self.$message.error(`${file.name}上传失败`)
      //     }
      //   })
      //   .catch((res) => {
      //   self.$message.error(`${file.name}上传失败`)
      // });
    },
  },
  created () { },
  mounted () {
    this.$EventBus.$on("removeFile", (file) => {
      this.handleRemove(file);
    });
  },
  beforeDestroy () {
    this.$EventBus.$off("removeFile");
  }
};
</script>
      
  <style lang="less" scoped>
.upload-item {
  cursor: pointer;
  border-radius: 3px;
  height: 30px;
  line-height: 30px;
  padding: 0 15px;
  border: 1px solid transparent;
  border-color: #b1b8c2;
  &:hover,
  &:focus {
    color: #639aff;
    border-color: #639aff;
  }
  svg {
    position: relative;
    top: -1px;
  }
  span {
    font-size: 14px;
    color: #445166;
    letter-spacing: 0;
    font-weight: 400;
    margin-left: 12px;
  }
}
</style>