<template>
  <div :style="style" ref="terminal" @contextmenu.prevent="(e) => contextmenuShow(e)"></div>
</template>

<script>
import { Terminal } from 'xterm';
import { AttachAddon } from 'xterm-addon-attach';
import { FitAddon } from 'xterm-addon-fit';
import 'xterm/css/xterm.css' // Import xterm.css
import { mapActions, mapState } from "vuex";
import { getChannel, blobToArrayBuffer } from '@/utils/axios-peer.js'

export default {
  name: 'SshTerminal',
  data() {
    return {
      terminal: null,
      fitAddon: null,
      webSocket: null,
      channel: null,
      reconnectInterval: 2000, // 重连间隔时间（毫秒）
      maxReconnectAttempts: 10, // 最大重连次数
      reconnectAttempts: 0, // 当前重连次数
      timer: null,
      init: false,
      hasPasted: false,
    }
  },
  computed: {
    ...mapState({
      fileManage: (state) => state.fileManage
    }),
    style () {
      let style = {
        'height': this.isFullScreen ? 'calc(100vh - 46px)': '284px', 'background': 'rgb(0, 0 ,0)'
      }
      return style
    }
  },
  props: { fileCode: String, drawerVisible: Boolean, file: Object, currentFullPathArr: Array, fileSystemId: [String, Number], fileType: String, callBack: Function, isFullScreen: Boolean, activeKey: String,  connectType: {type: String, default: 'fs_id'}},
  watch: {
    isFullScreen: {
      handler (val) {
        this.$nextTick(() => {
          if (this.fitAddon && this.fitAddon.fit) {
            this.fitAddon.fit();
            this.sendSize()
            this.terminal.focus()
          }
        })
      },
      immediate: true
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
    if (this.channel && this.channel.close) this.channel.close()
    this.channel = null
    if(this.terminal && this.terminal.dispose) this.terminal.dispose()
    this.terminal = null
    if(this.fitAddon && this.fitAddon.dispose) this.fitAddon.dispose()
    this.fitAddon = null
    window.removeEventListener('resize', this.resizeScreen);
  },
  mounted () {
    this.initTerminal()
  },
  methods: {
    handlePaste(event) {
      // 在这里处理粘贴事件
      const clipboardData = event.clipboardData || window.clipboardData;
      const pastedText = clipboardData.getData('text');
      console.log('当前粘贴板')
      if (pastedText) {
        this.hasPasted = true
      } else {
        this.hasPasted = false
      }
    },
    contextmenuShow (event) {
      event.stopPropagation()
      let items = [
        {
          icon: 'contextmenu-reconnect',
          label: "重连",
          onClick: () => {
            this.reconnectTerminal()
          },
        },
        {
          icon: 'contextmenu-copy',
          label: "复制",
          onClick: () => {
            if (!this.terminal) { return }
            let text = this.terminal.getSelection()
            if (text) global.utils.copyText(text, event);
          },
        },
        {
          icon: 'contextmenu-paste',
          label: "粘贴",
          onClick: () => {
            if (!this.terminal) { return }
            if (navigator.clipboard && typeof navigator.clipboard.readText === 'function') {
              navigator.clipboard.readText()
                .then(text => {
                  console.log('剪贴板中的文本内容：', text);
                  if (text) {
                    this.channel.send(text)
                    this.terminal.focus()
                  }
                })
                .catch(error => {
                  console.error('读取剪贴板失败：', error);
                });
            } else {
              console.warn('浏览器不支持 navigator.clipboard API');
            }
          },
        },
        {
          icon: 'contextmenu-clearall',
          label: "清屏",
          onClick: () => {
            if (!this.terminal) { return }
            if (!this.channel) { return }
            this.channel.send('clear\r')
            this.terminal.focus()
          },
        },
      ]
      this.$contextmenu({
        items: items,
        event,
        customClass: "custom-contextmenu",
        zIndex: 3000,
        minWidth: 80,
      });
      return false;
    },
    reconnectTerminal() {
      this.terminal.reset();
      this.terminal.dispose()
      this.terminal = new Terminal({ cursorBlink: true, convertEol: true });
      this.fitAddon = new FitAddon();
      this.terminal.loadAddon(this.fitAddon);
      this.terminal.open(this.$refs.terminal);
      this.connectWebSocket();
      // const attachAddon = new AttachAddon(this.channel);
      // this.terminal.loadAddon(attachAddon);
      if (this.fitAddon && this.fitAddon.fit) {
        this.fitAddon.fit();
        this.sendSize()
      }
      if (this.terminal) this.terminal.focus()
    },
    // 连接ws
    connectWebSocket() {
      let pwd = '/'
      if (this.currentFullPathArr && this.currentFullPathArr.length) {
        pwd = this.currentFullPathArr[this.currentFullPathArr.length - 1]?.fullpath
      }
      if (this.activeKey !== 'file' || this.fileSystemId !== this.fileManage.showItem.id) return false
      const params = {
        "target": this.fileSystemId + '', // 表示的目标的主机
        "type": this.connectType,
        "pwd": pwd,   
        "init": "whoami",
        "mode": "terminal",
      };
      console.log(this.fileSystemId, ' this.fileSystemId this.fileSystemId this.fileSystemId', this.fileManage.showItem.id)
      const encoder = new TextEncoder();
      const data = encoder.encode(JSON.stringify(params));
      const base64Params = btoa(String.fromCharCode.apply(null, data));
      const queryString = `${encodeURIComponent(base64Params)}`;
      // const url = new URL('ws://' + "alstra.cn:8866" + '/terminal');
      const arr = localStorage.getItem('FILE_SYSTEM_URL').split('//')
      const domin = arr[arr.length -1]
      if (!domin) return false
      const url = new URL('ws://' + domin + '/terminal');
      url.search = "q=" + queryString + "&cluster_id=" + this.fileManage.clusterId;
      let wsUrl = url.toString()
      // 获取一个仅供websocket使用的channel
      getChannel({ url: wsUrl }, "terminal" + Math.random(0,1)).then(channel => {
        this.channel = channel
        // 回显服务器信息
        let isReady = false
        let isReadyPromise = new Promise((resolve) => {
          channel.on('data', (data) => {
            const decoder = new TextDecoder();
            const text = decoder.decode(data);
            this.terminal.write(text);
            if (isReady || text.startsWith("远程连接已建立\r\n")) {
              resolve(true)
            }
          });
        });
        isReadyPromise.then((ready) => {
          // 发送认证信息
          const authinfo = { token: localStorage.getItem('token') }
          const blob = new Blob([JSON.stringify(authinfo)], { type: 'application/json' });
          blobToArrayBuffer(blob).then(buffer => {
            channel.send(buffer)
          })

          // 发送窗口设置大小信息
          const windowSize = { high: this.terminal.rows, width: this.terminal.cols };
          const sizeBlob = new Blob([JSON.stringify(windowSize)], { type: 'application/json' });
          blobToArrayBuffer(sizeBlob).then(buffer => {
            channel.send(buffer)
          })

          // 发送键盘输入信息
          this.terminal.onData((data) => {
            channel.send(data);
          });
        });
      }).catch(error => {
        this.terminal.write("连接失败" + error)
      });
    },
    initTerminal() {
      const self = this
      const terminal = new Terminal({cursorBlink: true, convertEol: true,});
      this.terminal = terminal
      const fitAddon = new FitAddon();
      this.fitAddon = fitAddon
      this.terminal.loadAddon(this.fitAddon);
      this.terminal.open(this.$refs.terminal);
      if (this.fitAddon && this.fitAddon.fit) this.fitAddon.fit();
      // 在此添加任何其他配置或自定义逻辑
      // 连接ws 
      this.connectWebSocket();
      const resizeScreen = () => {
        if (self.activeKey !== 'file') return false
        if (self.fitAddon && self.fitAddon?.fit) {
          self.fitAddon.fit();
          self.sendSize()
        }
      }
      this.resizeScreen = resizeScreen
      window.addEventListener('resize', this.resizeScreen, false);
      // const attachAddon = new AttachAddon(this.webSocket);
      // this.terminal.loadAddon(attachAddon); 
      if (this.terminal) this.terminal.focus();
    },
    sendSize () {
      if (this.activeKey !== 'terminal') return false
      if (!this.terminal) return false
      const windowSize = { high: this.terminal.rows, width: this.terminal.cols };
      console.log('窗口', windowSize)
      const blob = new Blob([JSON.stringify(windowSize)], { type: 'application/json' });
      blobToArrayBuffer(blob).then(buffer => {
        if(this.channel) this.channel.send(buffer)
      })
    }
  },
};
</script>
<style lang="less">
.ssh-drawer {
  .xterm-screen {
    width: calc(100vw - 280px - 96px)!important;
    height: 100%!important;
  }
}
</style>