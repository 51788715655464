<!--
 * @Author: mjzhu
 * @Date: 2022-06-08 17:37:26
 * @LastEditTime: 2024-03-27 18:32:30
 * @FilePath: \awx-ui\src\pages\groupManage\fileModule\software\installSoftware.vue
-->
<template>
  <a-spin :spinning="spinning">
  <div class="install-software">
    <div :class="['app-item-header']">
      <span class="app-logo">
        <!-- 系统图标 -->
        <img v-if="appItem.icon && !IsColorIcon(appItem.icon) && !IsNetWorkIcon(appItem.icon)" :src="appItem.icon " alt />
        <!-- 纯色图标 -->
       <div v-if="IsColorIcon(appItem.icon) && !IsNetWorkIcon(appItem.icon)" class="icon-item" :style="{
          'background': appItem.icon,
          'text-align': 'center',
          'width': '60px',
          'height': '60px',
          'border-radius': '8px',
          'margin': 'auto'
        }">
          <span :style="{
            'color': '#fff',
            'font-size': iconColorName(appItem.name).length === 1 ? '26px' : '20px',
            'width': '100%',
            'display': 'block',
            'height': '100%',
            'line-height': '60px'
          }">{{ iconColorName(appItem.name) }}</span>
        </div>
        <!-- 网络图标 -->
        <iconifyIcon v-if="appItem.icon && !IsColorIcon(appItem.icon) && IsNetWorkIcon(appItem.icon)" :icon="iconNetWorkName(appItem.icon)" style="font-size: 48px;" />
      </span>
      <div class="app-text-info">
        <div class="app-title over-flow-ellipsis-2" :style="{'font-size': appType === 'app' ? '16px': '16px'}" :title="appItem.name">{{appItem.name}}</div>
        <div v-if="appItem.description" class="app-description over-flow-ellipsis-2" :title="appItem.description">{{appItem.description}}</div>
        <div class="app-categories" v-if="!appItem.description">
          <div class="app-categories-item" v-for="(item, index) in appcategories" :key="index">
            {{ handler(item) }}
            <a-divider v-if="index !== appcategories.length - 1" type="vertical" />
          </div>
        </div>
      </div>
    </div>
    <div class="mgt26 app-softwares">
      <div class="softwares">
        <div class="label item">名称</div>
        <div class="label item">提供方</div>
        <div class="label item">版本</div>
        <div class="label item">适配平台</div>
        <div class="label item">状态</div>
        <div class="label item">操作</div>
      </div>
      <div class="softwares softwares-value" v-for="item in softWareList" :key="item.key">
        <div class="value item  over-flow-ellipsis" :title="item.name">{{ item.name}}</div>
        <div class="value item  over-flow-ellipsis" :title="item.vendor">{{ item.vendor || '—'}}</div>
        <div class="value item  over-flow-ellipsis" :title="item.version">{{ item.version || '—'}}</div>
        <div class="value item over-flow-ellipsis" :title="item.platform">{{ item.platform || '—'}}</div>
        <div class="value item status">{{ item.installed ? '已安装' : '未安装'}}</div>
        <div class="value item option">
          <span style="display:inline-block" @click.stop="handleSubmit(item)">
            {{ item.installed ? '卸载' : '安装'}}
          </span>
        </div>
      </div>
    </div>
    <div v-if="showEditor"  class="log">
      <InstallMonitor :fileSystemId="hostId" connectType="host_id" :software="softWareObj" :clusterId="clusterId" @setMonitorUrl="setMonitorUrl" @getAppSoftWareStatus="getAppSoftWareStatus" activeKey="share"></InstallMonitor>
    </div>
  </div>
</a-spin>
</template>
<script>
import InstallMonitor from "./installMonitor.vue";
export default {
  props: {
    callBack: Function,
    appItem: Object,
    software: Object,
    hostId: [String, Number],
    clusterId: String,
    loading: Boolean,
    appType: {
      type: String,
      default: "template",
    },
  },
  components: {
    InstallMonitor,
  },
  data() {
    return {
      spinning: false,
      installFlag: false,
      showEditor: false,
      jobId: "",
      timer: null,
      log: '',
      softwareId: '',
      softWareObj: this.software,
      softWareList: []
    };
  },
  watch: {
    loading: {
      handler(val) {
        this.spinning = val
      }
    },
    'appItem.id': {
      handler(val) {
        if (val) {
          this.getAppSoftWareStatus()
        }
      },
      immediate: true
    }
  },
  beforeDestroy() {
    if (this.timer) clearInterval(this.timer)
    this.timer = null;
  },
  computed: {
    APPSOFTWARESFILTER () {
      let arr = []
      arr =  this.isOpen ? _.cloneDeep(this.appItem?.app_softwares) : _.cloneDeep(this.APPSOFTWARES)
      return arr
    },
    APPSOFTWARES () {
      let arr = _.cloneDeep(this.appItem?.app_softwares)
      return arr
    },
    appcategories() {
      let arr = [];
      arr = this.appItem.categories ? this.appItem.categories.split(",") : [];
      return arr;
    },
  },
  methods: {
    getMoreId () {},
    IsColorIcon(icon) {
      return global.utils.IsColorIcon(icon);
    },
    iconColorName(name) {
      return global.utils.iconColorName(name);
    },
    IsNetWorkIcon(icon) {
      return global.utils.IsNetWorkIcon(icon);
    },
    iconNetWorkName (name){
      return global.utils.iconNetWorkName(name);
    },
    setMonitorUrl (installed) {
      this.$emit('setMonitorUrl', installed)
    },
    handleSubmit(item) {
      this.showEditor = true;
      this.installFlag = item.installed
      this.softWareObj = {
        ...item, 
        id: item.id + 'random' + Math.random(0, 1)
      }
    },
    install() {
      const self = this;
      const ajaxAPi = this.installFlag ? global.API.installSoftware(this.softwareId) : global.API.installSoftware(this.softwareId)
      this.$axiosGet(ajaxAPi + '?cluster_id=' + this.clusterId, {
        target: this.hostId + '',
        conn_timeout: 300,
        exec_timeout: 300,
        verbose: true,
      })
        .then((res) => {
          if ([200, 201, 204, 202].includes(res.status)) {
            this.showEditor = true;
            this.jobId = res.data.data;
            if (this.timer) clearInterval(this.timer)
            this.timer = null
            this.pollingSearch(res.data.data)
          }
        })
        .catch((err) => {});
    },
    getAppSoftWareStatus (type) {
      if (!type) this.spinning = true
      this.$axiosGet(global.API.getAppSoftWareStatus(this.appItem.id), {
        target: this.hostId + '',
        cluster_id: this.clusterId,
      }).then((res) => {
        this.spinning = false
        this.softWareList = res.data.data
      })
    }
  },
  mounted() {
  },
};
</script>
<style lang="less" scoped>
.p0 {
  padding: 0px !important;
}
.tip {
  display: flex;
  align-items: center;
  padding: 40px 0 40px 42px;
  font-size: 16px;
  color: #1d202d;
  border-bottom: 1px solid #dfe8f3;
}

.ant-modal-confirm-btns-new {
  margin: 0 0 !important;
  height: 50px !important;
}
.install-software {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 194px;
  max-height: 800px;
  overflow-y: auto;
  background: #fff;
  padding: 19px 20px 19px 20px;
  color: #adb0b8;
  border:  1px solid rgba(207,213,222,1);
  border-radius: 6px;

  border-radius: 6px;
  .app-item-header {
    display: flex;
  }
  .collect {
    position: absolute;
    top: -10px;
    right: -4px;
    font-size: 60px;
    z-index: 10;
  }
  .app-text-info {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .app-logo {
    height: 60px;
    margin: 0px 18px 0 0;
    width: 60px;
    min-width: 60px;
    line-height: 40px;
    background: #fff; // 这个是图标的颜色定义
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3px 10px 0px rgba(66, 82, 108, 0.2);
    border-radius: 8px;
    display: flex;
    img {
      width: 60px;
      border-radius: 8px;
      margin: auto;
    }
  }
  .app-title {
    flex: 1;
    line-height: 24px;
    font-weight: 600;
    font-size: 15px;
    color: #1d202d;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 24px;
  }
  .app-description {
    line-height: 20px;
    font-size: 12px;
    color: #7d838e;
    letter-spacing: 0;
    font-weight: 400;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
  }
  .app-categories {
    display: flex;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &-item {
      margin-top: 6px;
      font-size: 12px;
      color: #7d838e;
      display: inline-block;
    }
  }
  // 选中的这个模板
  &-selected {
    background: #f4faff;
    border: 1px solid rgba(57, 116, 244, 1);
    box-shadow: 0px 2px 10px 0px rgba(66, 82, 108, 0.2);
    border-radius: 6px;
  }
  .mgt26 {
    margin-top: 22px;
  }
  .app-softwares {
    position: relative;
    .softwares {
      display: flex;
      align-items: center;
      width: 100%;
      .item {
        width: 16.6%;
        padding-left: 10px;
        font-size: 12px;
        border-right: 1px solid rgb(226, 230, 235);
        &:last-child {
          border-right: none;
        }
        &:first-child {
          // padding-left: 0;
        }
      }
      .label {
        color: #7f838e;
        padding-bottom: 2px;
      }
      .value {
        min-height: 24px;
        color: #34343c;
        line-height: 24px;
      }
      .status {
        color: #07C060;
      }
      .option {
        cursor: pointer;
        color: #3974F4;
      }
    }
  }
  .pop-options {
    position: absolute;
    width: 100%;
    left: 0px;
    top: 100px;
    z-index: 1;
    width: 100%;
    border-radius: 6px;
    background-image: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
    box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.2);
    .option-item {
      width: 100%;
      height: 32px;
      line-height: 32px;
      padding-left: 24px;
      font-size: 14px;
      color: #34343c;
      &:hover {
        background: #f3f6f9;
      }
    }
  }
}
.install-tips {
  padding: 16px 24px;
}
.log {
  position: relative;
  width: 100%;
  height: 100%;
  // min-height: 194px;
  margin-top: 16px;
  padding-right: 20px;
  // background: #333;
}
.softwares-value {
  &:hover {
    background: #f0f7ff;
  }
}
</style>
