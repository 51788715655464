<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-09-27 15:06:50
 * @LastEditTime: 2024-03-18 10:33:17
 * @FilePath: \awx-ui\src\pages\groupManage\fileModule\cron\components\addCron.vue
-->
<template>
  <div class="cron-new-modal">
    <a-form-model ref="baseInfoFrom" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form" class="form-content-app" :rules="rules">
      <a-form-model-item label="常用设置" prop="commonSetting">
        <a-select v-model="form.categories" placeholder="请选择常用设置" @change="changeCron">
          <a-select-option :value="item.value" v-for="(item, index) in commonSettingList" :key="index">{{ item.label }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-row type="flex" style="position: relative">
        <a-col :span="14">
          <a-form-model-item :style="{'width': !!editFlag ? '99%' : '99%'}" label="分" prop="minute" :label-col="{
            xs: { span: 24 },
            sm: { span: 7},
          }" :wrapper-col="{
            xs: { span: 24 },
            sm: { span: 17 },
          }">
            <a-input v-model="form.minute" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
        <a-col :span="10">
          <a-form-model-item class="mgl6 w-100-child" :style="{'width': !!editFlag ? '100%' : '100%'}" prop="minuteHelp">
            <a-select v-model="form.minuteHelp" placeholder="请选择" @change="(val) => changeHelper(val, 'minute')">
              <a-select-opt-group>
                <span slot="label">--common settings--</span>
                <a-select-option :value="item.value" v-for="(item, index) in minuteCommonList" :key="index">{{ item.label }}</a-select-option>
              </a-select-opt-group>
              <a-select-opt-group>
                <span slot="label">--Minutes--</span>
                <a-select-option :value="item.value" v-for="(item, index) in loadTimeList(5)" :key="index">{{ item.label }}</a-select-option>
              </a-select-opt-group>
              <!-- loadTimeList -->
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row type="flex" style="position: relative">
        <a-col :span="14">
          <a-form-model-item :style="{'width': !!editFlag ? '99%' : '99%'}" prop="hour" label="时" :label-col="{
            xs: { span: 24 },
            sm: { span: 7},
          }" :wrapper-col="{
            xs: { span: 24 },
            sm: { span: 17 },
          }">
            <a-input v-model="form.hour" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
        <a-col :span="10">
          <a-form-model-item class="mgl6 w-100-child" :style="{'width': !!editFlag ? '100%' : '100%'}" prop="hourHelp">
            <a-select v-model="form.hourHelp" placeholder="请选择" @change="(val) => changeHelper(val, 'hour')">
              <a-select-opt-group>
                <span slot="label">--common settings--</span>
                <a-select-option :value="item.value" v-for="(item, index) in hourCommonList" :key="index">{{ item.label }}</a-select-option>
              </a-select-opt-group>
              <a-select-opt-group>
                <span slot="label">--Hours--</span>
                <a-select-option :value="item.value" v-for="(item, index) in loadTimeList(4)" :key="index">{{ item.label }}</a-select-option>
              </a-select-opt-group>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
   
        <a-row type="flex" style="position: relative">
          <a-col :span="14">
            <a-form-model-item :style="{'width': !!editFlag ? '99%' : '99%'}" prop="hour" label="天" :label-col="{
            xs: { span: 24 },
            sm: { span: 7},
          }" :wrapper-col="{
            xs: { span: 24 },
            sm: { span: 17 },
          }">
              <a-input v-model="form.day" placeholder="请输入" />
            </a-form-model-item>
          </a-col>
          <a-col :span="10">
            <a-form-model-item class="mgl6 w-100-child" :style="{'width': !!editFlag ? '100%' : '100%'}" prop="dayHelp">
              <a-select v-model="form.dayHelp" placeholder="请选择" @change="(val) => changeHelper(val, 'day')">
                <a-select-opt-group>
                  <span slot="label">--common settings--</span>
                  <a-select-option :value="item.value" v-for="(item, index) in dayCommonList" :key="index">{{ item.label }}</a-select-option>
                </a-select-opt-group>
                <a-select-opt-group>
                  <span slot="label">--Day--</span>
                  <a-select-option :value="item.value" v-for="(item, index) in loadTimeList(1)" :key="index">{{ item.label }}</a-select-option>
                </a-select-opt-group>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
 
        <a-row type="flex" style="position: relative">
          <a-col :span="14">
            <a-form-model-item :style="{'width': !!editFlag ? '99%' : '99%'}" prop="month" label="月" :label-col="{
            xs: { span: 24 },
            sm: { span: 7},
          }" :wrapper-col="{
            xs: { span: 24 },
            sm: { span: 17 },
          }">
              <a-input v-model="form.month" placeholder="请输入" />
            </a-form-model-item>
          </a-col>
          <a-col :span="10">
            <a-form-model-item class="mgl6 w-100-child" :style="{'width': !!editFlag ? '100%' : '100%'}" prop="monthHelp">
              <a-select v-model="form.monthHelp" placeholder="请选择" @change="(val) => changeHelper(val, 'month')">
                <a-select-opt-group>
                  <span slot="label">--common settings--</span>
                  <a-select-option :value="item.value" v-for="(item, index) in monthCommonList" :key="index">{{ item.label }}</a-select-option>
                </a-select-opt-group>
                <a-select-opt-group>
                  <span slot="label">--Month--</span>
                  <a-select-option :value="item.value" v-for="(item, index) in loadTimeList(3)" :key="index">{{ item.label }}</a-select-option>
                </a-select-opt-group>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row type="flex" style="position: relative">
          <a-col :span="14">
            <a-form-model-item :style="{'width': !!editFlag ? '99%' : '99%'}" prop="week" label="工作日" :label-col="{
            xs: { span: 24 },
            sm: { span: 7},
          }" :wrapper-col="{
            xs: { span: 24 },
            sm: { span: 17 },
          }">
              <a-input v-model="form.week" placeholder="请输入" />
            </a-form-model-item>
          </a-col>
          <a-col :span="10">
            <a-form-model-item class="mgl6 w-100-child" :style="{'width': !!editFlag ? '100%' : '100%'}" prop="weekHelp">
              <a-select v-model="form.weekHelp" placeholder="请选择" @change="(val) => changeHelper(val, 'week')">
                <a-select-opt-group>
                  <span slot="label">--common settings--</span>
                  <a-select-option :value="item.value" v-for="(item, index) in weekCommonList" :key="index">{{ item.label }}</a-select-option>
                </a-select-opt-group>
                <a-select-opt-group>
                  <span slot="label">--Month--</span>
                  <a-select-option :value="item.value" v-for="(item, index) in loadTimeList(2)" :key="index">{{ item.label }}</a-select-option>
                </a-select-opt-group>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row type="flex" style="position: relative">
          <a-col :span="10">
            <a-form-model-item :style="{'width': !!editFlag ? '99%' : '99%'}" prop="enable_log" label="" :label-col="{
              xs: { span: 24 },
              sm: { span: 0},
            }" :wrapper-col="{
              xs: { span: 24 },
              sm: { span: 20, offset: 10 },
            }">
                <a-checkbox :true-value="true" :false-value="false" v-model="form.enabled">启用任务</a-checkbox>
            </a-form-model-item>
            </a-col>
        </a-row>
      <a-form-model-item label="命令" prop="command">
        <a-input v-model="form.command" placeholder="请输入命令" type="textarea" :rows="4" :maxLength="1000" />
      </a-form-model-item>
      <a-form-model-item class="w-100-child">
        <div class="ant-modal-confirm-btns-new">
          <a-button @click.stop="formCancel">取消</a-button>
          <a-button style="margin-left: 10px" type="primary" @click.stop="handleSubmit">保存</a-button>
        </div>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<script>
export default {
  props: {
    detail: Object,
    clusterId: String,
    hostGroupId: [String, Number],
    callBack: Function,
    formType: {
      type: String,
      default: "dialog",
    },
  },
  components: {},
  data() {
    const self = this;
    return {
      rules: {
        minute: [
          {
            required: true,
            trigger: ["change", "blur"],
          },
        ],
        hour: [
          {
            required: true,
            trigger: ["change", "blur"],
          },
        ],
        day: [
          {
            required: true,
            trigger: ["change", "blur"],
          },
        ],
        week: [
          {
            required: true,
            trigger: ["change", "blur"],
          },
        ],
        month: [
          {
            required: true,
            trigger: ["change", "blur"],
          },
        ],
        command: [
          {
            required: true,
            trigger: ["change", "blur"],
          },
        ],
      },
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
      form: {
        command: "",
        commonSetting: undefined,
        enable_append: false,
        enabled: true,
        enable_log: false,
        minute: "",
        hour: "",
        day: "",
        week: "",
        month: "",
        minuteHelp: undefined,
        hourHelp: undefined,
        dayHelp: undefined,
        weekHelp: undefined,
        monthHelp: undefined,
      },
      categoriesList: [],
      tagsList: [],
      commonSettingList: [
        {
          label: "每隔一分钟（* * * * *）",
          value: "* * * * *",
        },
        {
          label: "每隔五分钟（(*/5 * * * *）",
          value: "*/5 * * * *",
        },
        {
          label: "每小时两次（0,30 * * * *）",
          value: "0,30 ** * *",
        },
        {
          label: "每小时一次（0 * * * *）",
          value: "0 * * * *",
        },
        {
          label: "每天两次（0 0,12 * * *）",
          value: "0 0,12 * * *",
        },
        {
          label: "每天一次（0 0 * * *）",
          value: "0 0 * * *",
        },
        {
          label: "每周一次（0 0 * * 0）",
          value: "0 0 * * 0",
        },
        {
          label: "每月在1日和15日（0 0 1,15 * *）",
          value: "0 0 1,15 * *",
        },
        {
          label: "每月一次（0 0 1 * *）",
          value: "0 0 1 * *",
        },
        {
          label: "每年一次（0 0 1 1 *）",
          value: "0 0 1 1 *",
        },
      ],
      minuteCommonList: [
        {
          label: "每分钟（*）",
          value: "*",
        },
        {
          label: "每两分钟（*/2）",
          value: "*/2",
        },
        {
          label: "每五分钟（*/5）",
          value: "*/5",
        },
        {
          label: "每十分钟（*/10）",
          value: "*/10",
        },
        {
          label: "每三十分钟（0,30）",
          value: "0,30",
        },
      ],
      hourCommonList: [
        {
          label: "每小时（*）",
          value: "*",
        },
        {
          label: "每两小时（*/2）",
          value: "*/2",
        },
        {
          label: "每三小时（*/3）",
          value: "*/3",
        },
        {
          label: "每四个小时（*/4)",
          value: "*/4",
        },
        {
          label: "每六个小时（*/6)",
          value: "*/6",
        },
        {
          label: "每十二个小时(1,12）",
          value: "0,12",
        },
      ],
      dayCommonList: [
        {
          label: "每天（*）",
          value: "*",
        },
        {
          label: "每隔一天（*/2）",
          value: "*/2",
        },
        {
          label: "在每月的1号和15号 (1,15)",
          value: "1,15",
        },
      ],
      monthCommonList: [
        {
          label: "每月（*）",
          value: "*",
        },
        {
          label: "每隔一个月（*/2）",
          value: "*/2",
        },
        {
          label: "每隔三个月（*/4）",
          value: "*/4",
        },
        {
          label: "每6个月（1,7）",
          value: "1,7",
        },
      ],
      weekCommonList: [
        {
          label: "每个工作日（*）",
          value: "*",
        },
        {
          label: "周一到周五（2-6）",
          value: "2-6",
        },
        {
          label: "周末（1,7）",
          value: "1,7",
        },
      ],
    };
  },
  watch: {
    formType: {
      handler(val) {
        if (val === "page") {
          this.labelCol = {
            xs: { span: 24 },
            sm: { span: 2 },
          };
          this.wrapperCol = {
            xs: { span: 24 },
            sm: { span: 22 },
          };
        }
      },
      immediate: true,
    },
  },
  computed: {
    editFlag() {
      const editFlag = this.$route?.query?.id || undefined;
      return editFlag;
    },
  },
  methods: {
    changeHelper(val, type) {
      this.form[`${type}`] = val;
    },
    handleSubmit () {
      const self = this;
      self.$refs.baseInfoFrom.validate(async (valid) => {
        if (valid) {
          let arr = [this.form.minute, this.form.hour, this.form.day, this.form.month, this.form.week, this.form.command]
          const params = {
            crond: arr.join(' '),
            // enable_append: this.form.enable_append,
            enabled:  this.form.enabled,
            // enable_log: this.form.enable_log,
            target: this.hostGroupId + ''
          };
          if (this.detail && this.detail?.id) {
            params.id = this.detail?.id
            this.$axiosPatch(global.API.getCrondList + '?cluster_id=' + this.clusterId , params).then((res) => {
              if ([200, 201, 204, 202].includes(res.status)) {
                self.$message.success('修改成功')
                self.callBack();
                self.$destroyAll();
              }
            });
          } else {
            this.$axiosJsonPost(global.API.getCrondList + '?cluster_id=' + this.clusterId , params).then((res) => {
              if ([200, 201, 204, 202].includes(res.status)) {
                self.$message.success('保存成功')
                self.callBack();
                self.$destroyAll();
              }
            });
          }
        }
      });
    },
    changeCron(val) {
      const arr = val.split(" ");
      const [minute, hour, day, month, week] = arr;
      this.form = {
        ...this.form,
        minute,
        day,
        month,
        week,
        hour,
      };
    },
    /**
     * 0 年
     * 1 天
     * 2 周
     * 3 月
     * 4 时
     * 5 分
     */
    loadTimeList(type) {
      let arr = [];
      if (type === 2) {
        arr = [
          { value: 0, label: "星期日(0)" },
          { value: 1, label: "星期一(1)" },
          { value: 2, label: "星期二(2)" },
          { value: 3, label: "星期三(3)" },
          { value: 4, label: "星期四(4)" },
          { value: 5, label: "星期五(5)" },
          { value: 6, label: "星期六(6)" },
        ];  
        return arr;
      }
      let sum = type === 5 ? 60 : type === 4 ? 24 : type === 1 ? 31 : type === 3 ? 12 : 60;
      for (let i = 0; i < sum; i++) {
        let label = i;
        if (i < 10 && type !== 3) label = "0" + i;
        // 分钟
        if (type === 5) {
          if (i === 0) {
            label = '00分钟（在小时开始时）(0)' 
          } else {
            label = label + '分钟（' + i + ')'
          }
        }
        // 时
        if (type === 4) {
          let hour = i < 12 ? 'am' : 'pm'
          label = i + ':00 ' + hour + ` (${i})`
        }
        // 天 
        if (type === 3) {
          label = (i+1) + '月 ' + `(${i + 1})`
        }
        arr.push({
          label:
            type == 1
              ? i === 30
                ? "最后一天"
                : "每月" + Number(i + 1) + "号" + ` (${i + 1})`
              : label,
          value: type == 1 ? (i === 30 ? "L" : Number(i + 1) + "") : type === 3 ?  Number(i + 1) + "" : i,
        });
      }
      return arr;
    },
    formCancel () {
      this.$destroyAll()
    },
    init () {
      this.form = {
        ...this.detail
      }
    }
  },
  mounted() {
    if (this.detail && this.detail?.id) {
      this.init()
    }
  },
};
</script>
<style lang="less" scoped>
.cron-new-modal {
  padding: 28px 0 0px 32px;
  .form-content-app {
    padding-right: 32px;
    // max-height: 580px;
    // height: 580px;
    // overflow-y: auto;
    /deep/ .ant-form-item-label > label {
      color: #34343c;
    }
    .icon-label {
      display: flex;
      align-items: center;
      position: relative;
      top: -42px;
      left: 14px;
      .icon-tips {
        position: relative;
        top: -1px;
        left: 4px;
        display: inline-block;
        border-radius: 50%;
        width: 14px;
        height: 14px;
        background: #c4cad3;
        line-height: 14px;
        text-align: center;
        color: #fff;
        font-size: 10px;
      }
    }
  }
}

/deep/.ant-modal-footer {
  display: none;
}
/deep/ .ant-form-item-label > label::after {
  content: "";
}
.w-100-child {
  /deep/ .ant-col {
    width: 98%;
  }
}
</style>
