<template>
  <div :style="style" ref="terminal"></div>
</template>

<script>
import { Terminal } from 'xterm';
import { AttachAddon } from 'xterm-addon-attach';
import { FitAddon } from 'xterm-addon-fit';
import 'xterm/css/xterm.css' // Import xterm.css
import { getChannel, blobToArrayBuffer } from '@/utils/axios-peer.js'

export default {
  name: 'SshTerminal',
  data() {
    return {
      terminal: null,
      fitAddon: null,
      webSocket: null,
      reconnectInterval: 2000, // 重连间隔时间（毫秒）
      maxReconnectAttempts: 10, // 最大重连次数
      reconnectAttempts: 0, // 当前重连次数
      channel: null,
      timer: null,
      init: false,
      hasPasted: false,
      handClose: false
    }
  },
  computed: {
    style () {
      let style = {
        'height': '500px', 'background': 'rgb(0, 0 ,0)'
      }
      return style
    }
  },
  props: { fileSystemId: [String, Number], activeKey: String, clusterId: String, software: Object, connectType: {type: String, default: 'fs_id'}},

  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
    this.handClose = true
    if (this.channel && this.channel.close) this.channel.close()
    this.channel = null
    if(this.terminal && this.terminal.dispose) this.terminal.dispose()
    this.terminal = null
    if(this.fitAddon && this.fitAddon.dispose) this.fitAddon.dispose()
    this.fitAddon = null
    window.removeEventListener('resize', this.resizeScreen);
  },
  mounted () {
    this.initTerminal()
    console.log(this.software, 'softwaresoftware')
  },
  watch: {
    'software.id': {
      handler (val) {
        const id  = val.split('random')[0]
        if (id) {
          if (this.timer) {
            clearTimeout(this.timer)
            this.timer = null
          }
          if (this.webSocket) this.webSocket.close()
          this.webSocket = null
          this.initTerminal()
        }
      },
    } 
  },
  methods: {
    // 连接ws
    connectWebSocket() {
      const self = this
      if (this.activeKey !== 'share') return false
      const params = {
        "target": this.fileSystemId + '', // 表示的目标的主机
        "type": "host_id",
        "cluster_id": this.clusterId,
        // "init": "whoami",
        "pwd": "/",
        "init": "whoami",
        "mode": "exec",
      };
      const encoder = new TextEncoder();
      const data = encoder.encode(JSON.stringify(params));
      const base64Params = btoa(String.fromCharCode.apply(null, data));
      const queryString = `${encodeURIComponent(base64Params)}`;
      const arr = localStorage.getItem('FILE_SYSTEM_URL').split('//')
      const domin = arr[arr.length -1]
      if (!domin) return false
      const str = this.software.installed ? '/uninstall' : '/install'
      const softWareId =  this.software.id.split('random')[0]
      const url = new URL('ws://' + domin + '/ws/software/' + softWareId + str);
      console.log('ws://' + domin + '/ws/software/' + softWareId + str , 'this.software.idthis.software.id')
      url.search = "q=" + queryString + "&cluster_id=" + this.clusterId;
      let wsUrl = url.toString()
      // 获取一个仅供websocket使用的channel
      getChannel({ url: wsUrl }, "terminal" + Math.random(0,1)).then(channel => {
        this.channel = channel
        // 回显服务器信息
        let isReady = false
        let isReadyPromise = new Promise((resolve) => {
          channel.on('data', (data) => {
            const decoder = new TextDecoder();
            const text = decoder.decode(data);
            this.terminal.write(text);
            if (isReady || text.startsWith("远程连接已建立\r\n")) {
              resolve(true)
            }
          });
          channel.on('close', (data) => {
            console.log('我来刷新')
            self.$emit('getAppSoftWareStatus', 'refresh')
          });
        });
        isReadyPromise.then((ready) => {
          // 发送认证信息
          const authinfo = { token: localStorage.getItem('token') }
          const blob = new Blob([JSON.stringify(authinfo)], { type: 'application/json' });
          blobToArrayBuffer(blob).then(buffer => {
            channel.send(buffer)
          })

          // 发送窗口设置大小信息
          const windowSize = { high: this.terminal.rows, width: this.terminal.cols };
          const sizeBlob = new Blob([JSON.stringify(windowSize)], { type: 'application/json' });
          blobToArrayBuffer(sizeBlob).then(buffer => {
            channel.send(buffer)
          })

          // 发送键盘输入信息
          this.terminal.onData((data) => {
            channel.send(data);
          });
        });
      }).catch(error => {
        this.terminal.write("连接失败" + error)
      });
      const resizeScreen = () => {
        if (self.activeKey !== 'share') return false
        if (self.fitAddon && self.fitAddon.fit) self.fitAddon.fit();
        this.sendSize();
      }
      this.resizeScreen = resizeScreen
      window.addEventListener('resize', this.resizeScreen, false);

      // const attachAddon = new AttachAddon(this.webSocket);
      // this.terminal.loadAddon(attachAddon);  
      if (this.terminal)  this.terminal.setOption('disableStdin', true);
    },
    // this.$emit('getAppSoftWareStatus', 'refresh')
    sendSize () {
      if (this.activeKey !== 'terminal') return false
      if (!this.terminal) return false
      const windowSize = { high: this.terminal.rows, width: this.terminal.cols };
      console.log('窗口', windowSize)
      const blob = new Blob([JSON.stringify(windowSize)], { type: 'application/json' });
      blobToArrayBuffer(blob).then(buffer => {
        if(this.channel) this.channel.send(buffer)
      })
    },
    initTerminal() {
      const terminal = new Terminal();
      this.terminal = terminal
      const fitAddon = new FitAddon();
      this.fitAddon = fitAddon
      this.terminal.loadAddon(this.fitAddon);
      this.terminal.open(this.$refs.terminal);
      if (this.fitAddon && this.fitAddon.fit) this.fitAddon.fit();
      // 连接ws 
      this.connectWebSocket();
    },
  },
};
</script>
<style lang="less">
.install-software {
  .xterm-screen {
    width: 100%!important;
    height: 100%!important;
  }
}
</style>