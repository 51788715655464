<!--
 * @Author: mjzhu
 * @Date: 2022-06-08 17:37:26
 * @LastEditTime: 2024-03-13 16:12:52
 * @FilePath: \awx-ui\src\pages\groupManage\fileModule\software\deleteApp.vue
-->
<template>
  <div>
    <a-form :label-col="labelCol" :wrapper-col="wrapperCol" :form="form" class="p0">
      <a-form-item :wrapper-col="{ span: 24}">
        <div class="tip">
          您确定要删除吗？
        </div>
      </a-form-item>
    </a-form>
    <div class="ant-modal-confirm-btns-new">
      <a-button @click.stop="formCancel">取消</a-button>
      <a-button style="margin-left: 10px" type="primary" @click.stop="handleSubmit">确定</a-button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    callBack: Function,
    clusterId: String,
    hostGroupId: [String, Number],
    ids: Array,
  },
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      form: this.$form.createForm(this),
    };
  },
  methods: {
    handleSubmit(e) {
      const self = this
      this.$axiosDelete(global.API.getCrondList + '?cluster_id=' + this.clusterId , {
        ids: this.ids,
        target: this.hostGroupId + ''
      }).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
          self.$message.success('操作成功')
          self.callBack();
          self.$destroyAll();
        }
      });
    },
    formCancel() {
      this.$destroyAll();
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.p0 {
  padding: 0px!important;
}
.tip {
  display: flex;
  align-items: center;
  padding: 20px 0 20px 42px;
  font-size: 16px;
  color: #1D202D;
  border-bottom: 1px solid #DFE8F3;
}

.ant-modal-confirm-btns-new {
  margin: 0px 10px 10px!important;
  height: 32px!important;
}
</style>
