<!--
 * @Author: mjzhu
 * @Date: 2022-06-13 14:04:05
 * @LastEditTime: 2024-03-28 09:16:34
 * @FilePath: \awx-ui\src\components\steps\components\formItem\formEditor.vue
-->
<template>
  <div class="terminal">
    <SshTerminal :fileSystemId="groupManage.showItem.id" connectType="host_id" :activeKey="activeKey" :file="file"></SshTerminal>
  </div>
</template>
<script>

import SshTerminal from '@/components/SshTerminal'
import { mapActions, mapState } from "vuex";


export default {
  name: "EditFile",
  components: {
    SshTerminal
  },
  props: { fileCode: String, file: Object, fileSystemId: [String, Number], fileType: String, callBack: Function, activeKey: String },
  data() {
    return {
      code: '$',
      loading: false,
      isFullScreen: false,
      editor: null
    };
  },
  watch: {
    'groupManage.showItem.id': {
      handler (val) {
      },
    }
  },
  computed: {
    ...mapState({
      groupManage: (state) => state.fileManage
    }),
    editorHeight() {
      let height = 'calc(100vh - 84px)'
      return height
    },
    language () {
      const language = 'shell'
      return language
    }
  },
  mounted () {
  },  
  beforeUnmount() {
  },
  methods: {
  },
};
</script>
<style lang="less" scoped>
.terminal {
  width: 100%;
  height: calc(100vh - 84px);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: black; 
  padding: 10px 0 0 10px;
}
</style>