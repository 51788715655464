var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['app-item']},[_c('div',{class:['app-item-header']},[_c('span',{staticClass:"app-logo"},[(_vm.appItem.icon && !_vm.IsColorIcon(_vm.appItem.icon) && !_vm.IsNetWorkIcon(_vm.appItem.icon))?_c('img',{attrs:{"src":_vm.appItem.icon,"alt":""}}):_vm._e(),(_vm.IsColorIcon(_vm.appItem.icon) && !_vm.IsNetWorkIcon(_vm.appItem.icon))?_c('div',{staticClass:"icon-item",style:({
      'background': _vm.appItem.icon,
      'text-align': 'center',
      'width': '60px',
      'height': '60px',
      'border-radius': '8px',
      'margin': 'auto'
    })},[_c('span',{style:({
      'color': '#fff',
      'font-size': _vm.iconColorName(_vm.appItem.name).length === 1 ? '26px' : '20px',
      'width': '100%',
      'display': 'block',
      'height': '100%',
      'line-height': '60px'
    })},[_vm._v(_vm._s(_vm.iconColorName(_vm.appItem.name)))])]):_vm._e(),(_vm.appItem.icon && !_vm.IsColorIcon(_vm.appItem.icon) && _vm.IsNetWorkIcon(_vm.appItem.icon))?_c('iconifyIcon',{staticStyle:{"font-size":"48px"},attrs:{"icon":_vm.iconNetWorkName(_vm.appItem.icon)}}):_vm._e()],1),_c('div',{staticClass:"app-text-info"},[_c('div',{staticClass:"app-title over-flow-ellipsis-2",style:({'font-size': _vm.appType === 'app' ? '16px': '16px'}),attrs:{"title":_vm.appItem.name}},[_vm._v(_vm._s(_vm.appItem.name))]),(_vm.appItem.description)?_c('div',{staticClass:"app-description over-flow-ellipsis-2",attrs:{"title":_vm.appItem.description}},[_vm._v(_vm._s(_vm.appItem.description))]):_vm._e(),(!_vm.appItem.description)?_c('div',{staticClass:"app-categories"},_vm._l((_vm.appcategories),function(item,index){return _c('div',{key:index,staticClass:"app-categories-item"},[_vm._v(" "+_vm._s(_vm.handler(item))+" "),(index !== _vm.appcategories.length - 1)?_c('a-divider',{attrs:{"type":"vertical"}}):_vm._e()],1)}),0):_vm._e()])]),_c('div',{staticClass:"mgt26 app-softwares"},[_vm._m(0),_vm._l((_vm.APPSOFTWARESFILTER),function(item){return _c('div',{key:item.key,staticClass:"softwares softwares-value"},[_c('div',{staticClass:"value item over-flow-ellipsis",attrs:{"title":item.name}},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"value item over-flow-ellipsis",attrs:{"title":item.platform}},[_vm._v(_vm._s(item.platform || '—'))]),_c('div',{staticClass:"value item status"},[_vm._v(_vm._s(item.installed ? '已安装' : '未安装'))]),_c('div',{staticClass:"value item option"},[_c('span',{staticStyle:{"display":"inline-block"},on:{"click":function($event){$event.stopPropagation();return _vm.opt(item)}}},[_vm._v(" 操作 ")])])])}),(_vm.hasMore)?_c('div',{staticClass:"more-action",on:{"click":function($event){$event.stopPropagation();return _vm.toggle.apply(null, arguments)}}},[_c('a-icon',{attrs:{"type":_vm.isOpen ? 'up' : 'down'}})],1):_vm._e()],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"softwares"},[_c('div',{staticClass:"label item"},[_vm._v("名称")]),_c('div',{staticClass:"label item"},[_vm._v("适配平台")]),_c('div',{staticClass:"label item"},[_vm._v("状态")]),_c('div',{staticClass:"label item"},[_vm._v("操作")])])
}]

export { render, staticRenderFns }