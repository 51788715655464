<!--
 * @Author: mjzhu
 * @Date: 2022-05-24 10:28:22
 * @LastEditTime: 2024-05-28 15:29:45
 * @FilePath: \awx-ui\src\pages\groupManage\fileModule\dashBoard\index.vue
-->

<template>
  <div class="dash-board">
    <div class="dash-board-content">
      <!-- to -->
      <div class="overview-card">
        <div class="overview-card-item" @click="changeChartType('cpu-load')">
          <div class="overview-card-icon">
            <svg-icon icon-class="cpu"></svg-icon>
            <!-- <icon :icon="'gravity-ui:cpu'" style="color: #999;"  /> -->
          </div>
          <div class="flex-warp-overview">
            <div class="title-word">
              <div class="label">CPU： {{monitor.cpu_core}}</div>
              <div class="value">{{ monitor.cpu ? monitor.cpu : '-' }}%</div>
            </div>
            <div>
              <a-progress :strokeWidth="8" :strokeColor="strokeColor(monitor.cpu)" :width="18" :showInfo="false" :percent="hanlderPercent(monitor.cpu)" />
            </div>
          </div>
        </div>
        <div class="overview-card-item" @click="changeChartType('memory')">
          <div class="overview-card-icon">
            <svg-icon icon-class="neicun"></svg-icon>
            <!-- <icon :icon="'bxs:memory-card'" style="color: #b2b2b2;" /> -->
          </div>
          <div class="flex-warp-overview" >
            <div class="title-word">
              <div class="label">内存： {{monitor.memory_total}}</div>
              <div class="value">{{ monitor.memory }}%</div>
            </div>
            <div>
              <a-progress :strokeWidth="8" :strokeColor="strokeColor(monitor.memory)" :width="18" :showInfo="false" :percent="hanlderPercent(monitor.memory)" />
            </div>
          </div>
        </div>
        <div class="overview-card-item" @click="changeChartType('')">
          <div class="overview-card-icon">
            <svg-icon icon-class="online"></svg-icon>
            <!-- <icon :icon="'streamline:wifi'" /> -->
          </div>
          <div class="flex-warp-overview">
            <div class="title-word">
              <div class="label">在线时长</div>
              <div class="value">{{ monitor.uptime }}</div>
            </div>
            <div style="height: 22px;width: 100%"></div>
          </div>
        </div>
        <div class="overview-card-item" @click="changeChartType('queue')">
          <div class="overview-card-icon">
            <svg-icon icon-class="fuzai"></svg-icon>
            <!-- <icon :icon="'carbon:load-balancer-pool'" /> -->
          </div>
          <div class="flex-warp-overview">
            <div class="title-word">
              <div class="label">平均负载</div>
              <div class="value">{{ monitor.load }}</div>
            </div>
            <div>
              <a-progress :strokeWidth="8" :strokeColor="strokeColor(monitor.load_progress)" :width="18" :showInfo="false" :percent="Number(monitor.load_progress)" />
            </div>
          </div>
        </div>
        <div class="overview-card-item" @click="changeChartType('')">
          <div class="overview-card-icon">
            <svg-icon icon-class="yingpan"></svg-icon>
            <!-- <icon :icon="'clarity:hard-disk-line'" /> -->
          </div>
          <div class="flex-warp-overview">
            <div class="title-word">
              <div class="label">根目录： {{monitor.disk_total}}</div>
              <div class="value">{{ monitor.disk }}</div>
            </div>
            <div>
              <a-progress :strokeWidth="8" :strokeColor="strokeColor(monitor.disk)" :width="18" :showInfo="false" :percent="hanlderPercent(monitor.disk)" />
            </div>
          </div>
        </div>
      </div>
      <!-- 图 -->
      <div class="chart">
        <div class="menu">
          <div class="title">
            <div>主机状态</div>
            <!-- <div class="tabs">
              <div @click="callback('cpu-load')" :class="['tabs-item', groupKey === 'cpu-load' && 'tabs-item-selected']">cpu</div>
              <div @click="callback('memory')" :class="['tabs-item', groupKey === 'memory' && 'tabs-item-selected']">内存</div>
              <div @click="callback('queue')" :class="['tabs-item', groupKey === 'queue' && 'tabs-item-selected']">负载</div>
            </div> -->
          </div>
          <div class="flex-container">
            <a-select class="mgr10" style="width: 120px" :value="groupKey" @change="callback">
              <a-select-option value="cpu-load">
                CPU
              </a-select-option>
              <a-select-option value="memory">
                内存
              </a-select-option>
              <a-select-option value="queue">
                负载
              </a-select-option>
            </a-select>
            <customDatePicker :currentSearchDate="currentSearchDate" :currentSearchDateText="currentSearchDateText" :setting="setting" @getTime="getTime" @getType="getType" />
          </div>
        </div>
          <!-- 为 ECharts 准备一个定义了宽高的 DOM -->
          <div :id="'echart'+currentFileSystemId" style="width: 100%; height: 90%"></div>
          <!-- <div v-show="groupKey === 'memory'" :id="'echart'+currentFileSystemId+groupKey" style="width: 100%; height: 90%"></div> -->
          <!-- <div v-show="groupKey === 'queue'"  :id="'echart'+currentFileSystemId+groupKey" style="width: 100%; height: 90%"></div> -->

            <!-- 为 ECharts 准备一个定义了宽高的 DOM -->
          <!-- <div :id="'echart'+currentFileSystemId" style="width: 100%; height: 90%"></div> -->
            <!-- 为 ECharts 准备一个定义了宽高的 DOM -->
          <!-- <div :id="'echart'+currentFileSystemId" style="width: 100%; height: 90%"></div> -->
      </div>
      <!-- 表格 -->
      <div class="tab">
        <div class="tab1">
          <div class="flex-bewteen-container">
            <div class="title">定时调度</div>
            <div class="see-more mgr10" @click="seeCron">
              查看更多
              <svg-icon style="font-size: 16px;color:#2b66ca;" icon-class="right-arrow" />
            </div>
          </div>
          <div class="table-info">
            <a-table :columns="columns" :dataSource="livelyList" :loading="liveLoading" row-key="id" :pagination="false">
              <span slot="recent_jobs" class="s" slot-scope="text,record">
                <span class="grid" v-for="item in record.detail.recent_jobs" :key="item.id">
                  <span :class="item.status=='successful' ? 'active2' : ''"></span>
                  <span :class="item.status=='failed' ? 'active' : ''"></span>
                </span>
              </span>
            </a-table>
          </div>
        </div>
        <!-- 表格二 -->
        <div class="tab1">
          <div class="flex-bewteen-container">
            <div class="title">系统信息</div>
          </div>
          <div class="table-info">
            <a-table :columns="columns2" :dataSource="runList" :loading="runLoading" :rowKey="(record)=>record['task_id']" :pagination="false"></a-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import customDatePicker from '@/components/customDatePicker'
import { mapState, mapMutations, mapActions } from "vuex";
import * as echarts from 'echarts';
import cronstrue from 'cronstrue/i18n';
import { formatBytes } from "@/utils/util";

const option = {
  title: {
    text: "",
  },
  // color: ["#F92D26", "#57A850"],
  tooltip: {
    trigger: "axis",
  },
  legend: {
    data:[],
    icon: "rect",
    // top: "0%",
    left: "5%",
    itemWidth: 12,
    itemHeight: 12,
  },
  grid: {
    top: document.documentElement.clientWidth < 1700 ? "22%" : "10%",
    left: "2.5%",
    right: "1.2%",
    bottom: "1.5%",
    containLabel: true,
  },

  xAxis: {
    type: "category",
    boundaryGap: false,
    data: [],
  },
  yAxis: {
    name: "",
    type: "value",
  },
  series: [],
};
export default {
  name: "overviewList",
  components: {
    customDatePicker,
  },
  props: {
    treeVisible: {
      type: Boolean,
      default: true
    },
    currentFileSystemId: [String, Number],
    fileSystemId: [String, Number],
  },
  beforeDestroy () {
    if (this.myChart) this.myChart.dispose()
    // 组件销毁时停止观察
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
  watch: {
    'groupManage.showItem.id': {
      handler (val, oldVal) {
        if (val === this.currentFileSystemId) {
          if (!this.myChart) {
            this.initDate()
            this.getMonitorData(this.groupManage.showItem.monitor);
            this.getCrondList()
          }
        }
      }
    },
    'groupManage.showItem.monitor': {
      handler (val, oldVal) {
        if (this.groupManage.showItem.id === this.currentFileSystemId && val) {
          this.getMonitorData(val);
        }
      },
      deep: true,
    }
  },
  data() {
    return {
      resizeObserver: null,
      myChart: null,
      groupKey: 'cpu-load',
      setting: {
        isDashBoard: true,
        dateFormat: 'YYYY-MM-DD HH:mm:ss',
        showMinute: false,
        showTwoMonth: false,
        showThreeMonth: false,
        allowClear: false,
        clearDate: false,
        disabledDate: true,
        // timeForMat: 'HH'
        timeForMat: 'HH:mm:ss',
      },
      date: "week",
      type: "all",
      menuList: {
        month: "month",
        two_weeks: "two_weeks",
        day: "day",
        week: "week",
      },
      changeType: 'day',
      params: {
        start_time: '',
        end_time: ''
      },
      runList: [],
      livelyList: [],
      liveLoading: false,
      runLoading: false,
      successfulArr: [],
      successfulList: [],
      failedArr: [],
      failedList: [],
      seriesArr: [],
      currentSearchDate: [],
      currentSearchDateText: '最近6小时',
      series2Arr: [],
      monitor: {
        cpu: '0',
        memory: '0',
        uptime: '-',
        disk: '0',
        load: '-',
        load_progress: '0'
      },
      columns: [
        {
          title: "命令",
          key: "command",
          ellipsis: true,
          dataIndex: "command",
          width: 300,
          customRender: (text, row, index) => {
            return <span title={text}>{text ? text : "-"}</span>;
          },
        },
        {
          title: "状态",
          key: "enabled",
          width: 110,
          dataIndex: "enabled",
          customRender: (text) => {
            return <span class={['status-text', text ? 'success-text' : 'fail-text']}>{text ? '在线' : '停用'}</span>
          },
        },
        {
          title: "调度描述",
          key: "cron",
          ellipsis: true,
          dataIndex: "cron",
          customRender: (text, row, index) => {
            return <span title={this.humanReadable(row)}>{this.humanReadable(row)}</span>;
          },
        },
      ],
      columns2: [
        {
          title: "属性名称",
          key: "name",
          dataIndex: "name",
          ellipsis: true,
          customRender: (text, record) => {
            return (
              <span
                class="task-status"
                title={text}
              >
              {text ? text : '-'}                
              </span>
            );
          },
        },
        {
          title: "属性值",
          key: "value",
          dataIndex: "value",
          customRender: (text) => {
            return (
              <span>
                {text ? text : '-'}                
              </span>
            );
          },
        },
      ],
    };
  },
  computed: {
    ...mapState('schedule', ['scheduleDict']),
    ...mapState({
      groupManage: (state) => state.fileManage
    }),
  },
  methods: {
    ...mapActions("task", ["setTaskInfo", "changeFirstEdit", "setJumpTaskDetail", "setTaskIndexShow", "setFlowIndexShow"]),
    strokeColor (num) {
      if (num.includes("%")) num = num.split('%')[0]
      let floatNum = parseFloat(num)
      const a = parseInt(floatNum)

      if ((floatNum > '0.0' || floatNum == '0.00') && floatNum < '80.0') {
        return '#6590ED'
      } else if (a === 80 || (floatNum > '80.0' && floatNum < '90.0')) {
        return '#f0be42'
      } else {
        return '#c73841'
      }
    },
    hanlderPercent (num) {
      if (num === '-') return 0
      if (num.includes("%")) num = num.split('%')[0]
      return Number(num)
    },
    humanReadable(record) {
      const cron = record.minute + ' ' + record.hour + ' ' + record.day + ' ' + record.month + ' ' + record.week
      // 使用 cronstrue 将 Cron 表达式转换为人类可读的形式
      let str = '-'
      try {
        str = cronstrue.toString(cron, { locale: "zh_CN", use24HourTimeFormat: true })
      } catch (err) {
        str = '-'
      }
      return str
    },
    seeCron () {
      this.$emit('seeCron')
    },
    changeChartType (key) {
      if (!key) return false
      if (key === this.groupKey) return false
      this.groupKey = key
      this.linelist()
    },
    callback (key) {
      this.groupKey = key
      this.linelist()
    },
    initDate () {
      const currentDate = moment();
      // 计算最近两周的起始日期（往前推14天）
      const startDate = currentDate.clone().subtract(5, 'hours').format('YYYY-MM-DD HH:mm:ss');
      // 计算最近两周的结束日期
      const endDate = currentDate.clone().format('YYYY-MM-DD HH:mm:ss');
      this.currentSearchDate = [startDate, endDate]
      this.changeType = 'hours'
      if (this.currentFileSystemId !== this.groupManage.showItem.id) return false
      this.linelist();
    },
    getType (type) {
      this.changeType = type
    },
    getTime (date, text, type) {
      if (this.currentFileSystemId !== this.groupManage.showItem.id) return false
      this.currentSearchDate = date
      this.currentSearchDateText = text
      // 增加参数 事件查询
      // this.params.period = val;
      this.linelist();
    },
    toRouteSingle(record, route) {
      if (route === '任务' && record.job_type === '3') {
        this.setFlowIndexShow('history')
        return this.$router.push({
          name: '流程',
          params: {
            obj: record,
          },
        });
      }
      this.setTaskIndexShow('history')
      this.$router.push({
        name: route,
        params: {
          obj: record,
        },
      });
    },
    toRoute(route) {
      this.$router.push("/" + route);
    },
    lineEcharts(options) {
      const self = this
      const dom = document.getElementById(`echart${this.currentFileSystemId}`)
      if (self.myChart) {
        self.myChart.dispose()
        self.myChart = null
      }
      if (!dom) return false
      let myChart = echarts.init(dom);
      self.myChart = myChart
      if (!self.myChart) return false
      // 使用刚指定的配置项和数据显示图表。
      self.myChart.setOption(options);
      // window.onresize = function () {
      //   self.myChart.resize();
      // };
      this.initResizeObserver();
    },
    initResizeObserver() {
      // 获取容器元素
      const container = document.getElementById(`echart${this.currentFileSystemId}`)

      // 创建 ResizeObserver 实例并指定回调函数
      this.resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          if (this.myChart) this.myChart.resize()
          const { width, height } = entry.contentRect;
          console.log('容器的宽度:', width);
          console.log('容器的高度:', height);
        }
      });

      // 开始观察容器的大小变化
      this.resizeObserver.observe(container);
    },
    handlerScheduleIcon (id) {
      const obj = this.scheduleDict.find(item => item.id === id) 
      const icon = obj?.icon || ''
      return icon
    },
    timestampToTime(timestamp) {
      var date = new Date(timestamp);
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
    // 自定义比较函数
    customSort(a, b) {
      // 提取'name'属性中的数字部分进行比较
      let numA = parseInt(a.split('-')[1]);
      let numB = parseInt(b.split('-')[1]);
      return numA - numB;
   },
    // 自定义比较函数
    customSortName(a, b) {
      // 提取'name'属性中的数字部分进行比较
      let numA = parseInt(a.name.split('-')[1]);
      let numB = parseInt(b.name.split('-')[1]);
      return numA - numB;
   },
    linelist() {
      let params = {
        cluster_id: this.groupManage.clusterId,
        start_time: this.currentSearchDate[0],
        end_time: this.currentSearchDate[1],
      }
      let mertics = this.groupKey === 'memory' ? 'memused-percent' : this.groupKey === 'cpu-load' ? 'user,system,iowait' : 'ldavg-1,ldavg-5,ldavg-15'
      this.$axiosGet(global.API.getHostMertics(this.groupManage.showItem.id + '', this.groupKey + '/' + mertics), params).then(
        (res) => {
          let result = {
            time: []
          }
          let chartOptions = {...option}
          const requestData = res.data.data[`${this.groupManage.showItem.id + ''}`]
          if (requestData?.time_series.length) {
            requestData?.time_series.forEach(item => {
              const time = moment.utc(`${item.timestamp.date} ${item.timestamp.time}`).utcOffset(8).format('YYYY/MM/DD HH:mm:ss')
              result.time.push(time);
              for (const key in item[`${this.groupKey}`]) {
                if (!result[key]) {
                  result[key] = [];
                }
                result[key].push(item[`${this.groupKey}`][key]);
              }
            });
          }
          chartOptions.xAxis.data = []
          chartOptions.legend.data = []
          chartOptions.series = []
          chartOptions.xAxis.data = result.time
          for (let key in result) {
            if (key !== 'time') {
              chartOptions.legend.data.push(key)
              chartOptions.series.push({
                name: key,
                type: "line",
                smooth: true,
                symbol: 'none', // 设置为空心点
                lineStyle: {
                  normal: {
                    width: 3,
                  },
                },
                data: result[key]
              })
            }
          }
          if (this.groupKey === 'queue') {
            chartOptions.legend.data.sort(this.customSort)
            chartOptions.series.sort(this.customSortName)
          }
          this.lineEcharts(chartOptions);
        }
      );
    },
    getMonitorData (monitor) {
      if (!monitor) return false
      const ids = this.groupManage.showItem.id
      this.monitor = {
        cpu: monitor[ids]?.cpu ? parseFloat(monitor[ids].cpu).toFixed(2) || '-' : '-',
        memory: monitor[ids]?.memory ? parseFloat(monitor[ids].memory).toFixed(2) || '-' : '-',
        disk: (monitor[ids]?.disk) || '-',
        uptime: (monitor[ids] && monitor[ids]?.uptime) ? monitor[ids].uptime.includes(':') ? monitor[ids].uptime : (monitor[ids]?.uptime + '天') : '-',
        load: (monitor[ids]?.load) || '-',
        load_progress: (monitor[ids]?.load) ? (monitor[ids]?.load).split(' ')[2] * 100 + '' : '',
        host_name: monitor[ids]?.host_name || '-',
        platform: monitor[ids]?.platform || '-',
        cpu_xh: monitor[ids]?.cpu_info.includes('@') ? monitor[ids]?.cpu_info.split('@')[0] : '-',
        cpu_sv: monitor[ids]?.cpu_info.includes('@') ? monitor[ids]?.cpu_info.split('@')[1] : '-',
        kernel_info: monitor[ids]?.kernel_info || '-',
        os_info: monitor[ids]?.os_info || '-',
        cpu_core: monitor[ids]?.cpu_core ?  monitor[ids]?.cpu_core + '核' : '',
        disk_total: monitor[ids]?.disk_total ? formatBytes(monitor[ids]?.disk_total * 1024) : '',
        memory_total: monitor[ids]?.memory_total ? formatBytes(monitor[ids]?.memory_total * 1024) : '',
      }
      let arr = [
        {name: '主机名称', value: this.monitor.host_name},
        {name: '平台架构', value: this.monitor.platform},
        {name: '发行版本', value: this.monitor.kernel_info},
        {name: '内核版本', value: this.monitor.os_info},
        {name: 'CPU型号', value: this.monitor.cpu_xh + this.monitor.cpu_sv},
      ]
      this.runList = arr
    },
    getCrondList() {
      this.init = true
      this.dataSource = []
      this.liveLoading = true;
      const params = {
        page_size: 1000,
        page: 1,
        keyword: this.params.name || "",
        target: this.groupManage.showItem.id + '',
        verbose: true
      };
        // 初始化的时候获取所有主机展示
      let apiAjax = global.API.getCrondList + '?cluster_id=' + this.groupManage.clusterId
      this.$axiosGet(apiAjax, params).then((res) => {
        this.liveLoading = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          let dataSource = []
          res.data.data.data.map(item => {
            const arr = item.crond ? item.crond.split(' ') : []
            const [minute, hour, day, month, week] = arr
            const command = arr.slice(5);
            dataSource.push({
              ...item,
              minute,
              day,
              month,
              hour,
              week,
              command: command.join(' ')
            })
          })
          this.livelyList = dataSource.splice(0,5)
        }
      });
    },
  },
  mounted() {
    const showItemKey = this.groupManage.showItem.key
    this.showItemKey = showItemKey
    if (this.groupManage.showItem.treeType === 'file') {
      setTimeout(() => {
        if (this.currentFileSystemId !== this.groupManage.showItem.id) return false
          this.initDate()
          this.getMonitorData(this.groupManage.showItem.monitor);
          this.getCrondList()
        }, 500)
    }
  },
  created() {
  },
};
</script>

<style lang="less" scoped>
.dash-board {
  padding: 20px 20px 30px 20px;
  height: calc(100vh - 84px);
  background: #F7F8FA;
  box-shadow: none;
  overflow-y: auto;
  // margin-bottom: 30px;
  &-content {
    height: calc(100% - 20px);
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .title {
    font-size: 17px;
    color: #1d202d;
    letter-spacing: 0;
    font-weight: 600;
  }
  .task-zhixing {
    cursor: pointer;
    &:hover {
      color: @primary-color;
    }
  }
  @keyframes rotation {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  .task-running {
    transform: rotate(360deg);
    animation: rotation 2s linear infinite;
  }
  .see-more {
    font-size: 14px;
    color: #2b66ca;
    letter-spacing: 0;
    font-weight: 400;
    cursor: pointer;
    
  }
  .task-status {
    svg {
      font-size: 18px;
      margin-right: 10px;
    }
  }
  .name {
    color: #2b66ca;
  }
  .name:hover {
    color: #2b66ca;
    text-decoration: underline;
    cursor: pointer;
  }
  .overview-card {
    margin: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 90px;
    min-height: 90px;
    margin-bottom: 16px;
    .overview-card-item {
      width: calc(20% - 14px);
      background: #FFFFFF;
      border: 1px solid rgba(207,213,222,1);
      border-radius: 6px;
      display: flex;
      align-items: center;
      padding: 27px;
      cursor: pointer;
      .overview-card-icon svg {
        font-size: 40px;
        margin-right: 22px;
      }
      .flex-warp-overview {
        max-height: 50px;
        min-height: 50px;
        flex: 1;
        height: 50px;
        color: #34343c;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .title-word {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .label {
            font-size: 14px;
            color: #1D202D;
            font-weight: 600;
          }
          .value {
            font-size: 14px;
            color: #1D202D;
            letter-spacing: 0;
            font-weight: 600;
          }
        }
        .word-red {
          color: #f3372d;
        }
        .word-blue {
          color: #2b66ca;
        }
      }
    }
    .ant-select {
      font-size: 14px;
      position: absolute;
      top: 0;
      left: 100;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }
  .chart {
    margin: auto;
    width: 100%;
    flex: 1;
    background: #ffffff;
    padding: 16px 20px;
    position: relative;
    border-radius: 4px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 15%);
    .menu {
      display: flex;
      justify-content: space-between;
      .ant-select-selection--single {
        margin-right: 10px;
      }
      .title {
        display: flex;
        .tabs {
          margin-left: 20px;
          display: flex;
          position: relative;
          height: 24px;
          .tabs-item {
            font-weight: 400;
            font-size: 15px;
            margin-right: 10px;
            cursor: pointer;
            &-selected {
              border-bottom: 2px solid @primary-color;
            }
          }
        }
      }
    }
    .main {
      width: 100%;
      height: 90%;
    }
  }
  .tab {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    height: 334px;
    min-height: 334px;
    border-radius: 4px;
    .tab1 {
      width: calc(50% - 8px);
      background: #ffffff;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      padding: 16px 20px;
      .table-info {
        height: 88%;
        overflow-y: auto;
        margin-top: 16px;
      }
    }
  }
  .time-menu {
    width: 30px;
  }
  .grid {
    display: flex;
    flex-direction: column;
    margin: 1px;
    span {
      width: 20px;
      height: 8px;
      background: #f1f6fa;
      border: 1px solid rgba(222, 226, 228, 1);
      border-radius: 1px;
    }

    .active {
      background: #f92d26;
      border: 1px solid #f92d26;
    }
    .active2 {
      background: #57a850;
      border: 1px solid #57a850;
    }
  }
  .s {
    display: flex;
  }
}
@media (max-width: 1700px) {
  .main { 
    height: 82%!important;
   }
}
</style>
