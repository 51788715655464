<!--
 * @Author: mjzhu
 * @Date: 2022-06-09 10:11:22
 * @LastEditTime: 2024-05-27 15:50:00
 * @FilePath: \awx-ui\src\pages\newHostManage\hostList.vue
-->

<template>
  <div class="host-list">
    <a-card class="table-card">
      <div class="table-info">
        <a-row type="flex" class="opt" :style="{'bottom': dataSource.length ? '16px' : '-46px' }">
            <a-col :span="clientWidth < 1700 ? 24 : 24" style="text-align: right">
              <!-- <a-button @click="runCommond()">运行命令</a-button> -->
              <a-button @click="openSsh">打开终端</a-button>
              <a-button style="margin-left: 10px" @click="batchLabel()">添加标签</a-button>
              <a-button style="margin-left: 10px" @click="batchService()">添加服务</a-button>
              <a-button style="margin-left: 10px" @click="delHost()">删除</a-button>
              <a-button style="margin-left: 10px" @click="onSearch">刷新</a-button>
              <a-button class="mgl10" type="primary" style="z-index: 1" @click="createHost({})"><svg-icon class="btn-before-icon mgr6" icon-class="file-add"></svg-icon>添加主机</a-button>
              <a-button class="mgl10" type="primary" style="z-index: 1" @click="importHost({})"><svg-icon style="margin-right: 8px;" class="btn-before-icon" icon-class="host-import"></svg-icon>导入主机</a-button>
            </a-col>
          </a-row>
        <a-table class="opt-table table-15" @change="tableChange" :row-selection="rowSelection" :columns="columns" :loading="loading" :dataSource="dataSource" :customRow="handleClickRow" rowKey="id" :pagination="pagination">
        </a-table>  
      </div>
    </a-card>
    <div class="host-footer">
       <div class="flex-container mgb16">
        <a-auto-complete v-model="dest"
            class="mgr12"
              style="width: 320px"
              :data-source="labelList" placeholder="请选择或输入目标路径" @change="handleChange" @select="handleSelect" />
        <a-input-search placeholder="输入关键字，查询主机" :value="params.name" @change="(value) => getVal(value, 'name')" @pressEnter="onSearch('selected')" @search="onSearch('selected')" />
      </div>
      <bottom-ope ref="bottomOptRef" :isFullScreen="isFullScreen" @closeFullScreen="closeFullScreen" :clusterId="groupManage.clusterId" @fullScreen="fullScreen" @onClose="onClose"  :selectedRows="selectedRows" @confirm="confirm"/>
    </div>
  </div>
</template>
  
  <script>
import AddHost from "./components/addHost.vue";
import UploadFile from "./components/uploadFile.vue";
import DeleteHost from "./components/deleteHost.vue";
import BatchLabel from "./components/batchLabelModal.vue";
import BatchServices from "./components/batchServicesModal.vue";
import { mapActions, mapState, mapGetters} from "vuex";
import HostEmpty from "@/components/empty-host/index.vue";
const clientWidth = document.documentElement.clientWidth
import BottomOpe from './components/bottomOpe/index..vue';

export default {
  name: "HOSTLIST",
  watch: {
    'groupManage.searchValue': {
      handler (val) {
        this.params.name = val
      },
      immediate: true
    },
    'groupManage.showItem.key': {
      handler (val) {
        if (val && val === this.showItemKey && this.dataSource.length > 0) {
          this.noRequest = false
          // this.pollingSearch(this.dataSource)
        }
      },
    }
  },
  provide() {
    return {
      fileSync: this.syncFile
    }
  },
  inject: ['convertTreeData', 'onSelectFileTree'],
  components: {BottomOpe},
  data() {
    return {
      timer: null,
      noRequest: false,
      dest: undefined,
      drawerVisible: true,
      isFullScreen: false,
      clientWidth,
      params: {},
      isHost: true,
      pagination: {
        total: 0,
        pageSize: 100,
        current: 1,
        showTotal: (total) => `共 ${total} 条`,
      },
      labelList: [],
      cluster_id: '',
      setSelectedIndex: -1,
      showAdd: false,
      dataSource: [],
      loading: false,
      selectedRowKeys: [],
      selectedRows: [],
      orderBy: "",
      columns: [
        {
          title: "主机名",
          key: "name",
          dataIndex: "name",
          width: 140,
          sorter: true,
          ellipsis: true,
          customRender: (text, row, index) => {
            return <span title={text}>{text ? text : "-"}</span>;
          },
        },
        {
          title: "主机IP",
          key: "ip",
          width: 140,
          ellipsis: true,
          dataIndex: "ip",
          customRender: (text, row, index) => {
            return <span title={text}>{text ? text : "-"}</span>;
          },
        },
        // {
        //   title: "同步目录",
        //   dataIndex: "dest",
        //   key: "dest",
        //   ellipsis: true,
        //   customRender: (text, row, index) => {
        //     return <span onClick={(e) => e.stopPropagation()}>{row.editable ? <a-input
        //     style="height: 28px"
        //     value={text}
        //     onBlur={(e) =>
        //       this.changeTableDest(e.target.value, row.id, "dest")
        //     }
        //       /> : <div>
        //         {text}
        //         <span class="mgl12" onClick={() => this.edit(row.id)}>
        //           <a-icon type="edit" />
        //         </span>
        //       </div> 
        //     }</span>;
        //   },
        // },
        {
          title: "端口",
          key: "port",
          width: 90,
          ellipsis: true,
          dataIndex: "port",
        },
        {
          title: "操作系统",
          key: "osType",
          width: 120,
          ellipsis: true,
          dataIndex: "osType",
          customRender: (text, row, index) => {
            return <span>
              <svg-icon iconClass={text === 'Linux' ? 'linux' : 'windows'}></svg-icon>
              <span class="mgl8" title={ text }>{text ? text : '-'}</span>
            </span>
          },
        },
        {
          title: "内存%",
          key: "memory",
          width: 90,
          ellipsis: true,
          dataIndex: "memory",
          customRender: (text, row, index) => {
            return <span title={text}>{text ? (
              <span class={this.handlerCss(text)}>{text}</span>
            ) : "-"}</span>;
          },
        },
        {
          title: "CPU%",
          key: "cpu",
          width: 90,
          ellipsis: true,
          dataIndex: "cpu",
          customRender: (text, row, index) => {
            return <span title={text}>{text ? (
              <span class={this.handlerCss(text)}>{text}</span>
            ) : "-"}</span>;
          },
        },
        {
          title: "磁盘",
          key: "disk",
          width: 90,
          ellipsis: true,
          dataIndex: "disk",
          customRender: (text, row, index) => {
            return <span title={text}>{text ? (
              <span class={this.handlerCss(text)}>{text}</span>
            ) : "-"}</span>;
          },
        },
        {
          title: "在线时长",
          key: "uptime",
          width: 110,
          ellipsis: true,
          dataIndex: "uptime",
          customRender: (text, row, index) => {
            return <span title={text}>{text ? (
              <span>{text}</span>
            ) : "-"}</span>;
          },
        },
        {
          title: "负载",
          key: "load",
          width: 150,
          ellipsis: true,
          dataIndex: "load",
          customRender: (text, row, index) => {
            return <span title={text}>{text ? (
              <span>{text}</span>
            ) : "-"}</span>;
          },
        },
        {
          title: "主机标签",
          key: "labels",
          ellipsis: true,
          dataIndex: "labels",
          customRender: (text, row, index) => {
            return <span title={ text.join(",") }>{text ? text.join(",") : '-'}</span>;
          },
        },
        // {
        //   title: "主机服务",
        //   key: "vars",
        //   dataIndex: "vars",
        //   ellipsis: true,
        //   customRender: (text, row, index) => {
        //     return (
        //       <span title={this.handlerVars(text)}>
        //         {this.handlerVars(text)}
        //       </span>
        //     );
        //   },
        // },
        {
          title: "主机组",
          key: "group_name",
          dataIndex: "group_name",
          ellipsis: true,
          customRender: (text, row, index) => {
            return <span title={ text }>{text ? text : "-"}</span>;
          },
        },
        {
          title: "操作",
          key: "action",
          width: 126,
          customRender: (text, row, index) => {
            return (
              <span class="flex-container">
                <a class="btn-opt" onClick={(e) => this.createHost(row, e)}>
                  编辑
                </a>
                <a-divider type="vertical" />
                <a class="btn-opt" onClick={(e) => this.deleteHost(row, e)}>
                  删除
                </a>
              </span>
            );
          },
        },
      ],
    };
  },
  computed: {
    ...mapState({
      groupManage: (state) => state.fileManage,
    }),
    ...mapGetters("masterMd", ["hasMaster"]),
    //复选框 需要时候再加
    rowSelection() {
      const { selectedRowKeys } = this;
      let _this = this;
      return {
        selectedRowKeys,
        onSelectAll: (selectedRowKeys, selectedRows) => {
          _this.selectedRows = selectedRows;
        },
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys;
          _this.selectedRows = selectedRows;
        },
        getCheckboxProps: (record) => {
          return {
            props: {
              name: record.name,
            },
          };
        },
      };
    }
  },
  beforeDestroy () {
    clearTimeout(this.timer)
    this.timer = null
  },
  methods: {
    ...mapActions("fileManage", [
      "changeRealEditList",
      "changeShowItem",
    ]),
    ...mapActions("runHistory", [
      "getHistoryList",
      "setRunningFlag",
      "setRunTab",
      "setNumAdd",
    ]),
    ...mapActions("masterMd", ["setHasMaster"]),
    creatMaster() {
      this.$destroyAll();
    },
    handlerCss (num) {
      let floatNum = parseFloat(num)
      const a = parseInt(floatNum)
      if ((floatNum > '0.0' || floatNum == '0.00') && floatNum < '80.0') {
        return 'green-tag'
      } else if  (a === 80 || (floatNum > '80.0' && floatNum < '90.0')) {
        return 'yellow-tag'
      } else {
        return 'red-tag'
      }
    },
    onClose () {
      // this.drawerVisible = false
      // this.isFullScreen = false
    },
    fullScreen() {
      this.isFullScreen = true;
    },
    closeFullScreen() {
      this.isFullScreen = false;
    },
    openSsh () {
      if (this.selectedRows.length == 0) {
        this.$message.warning("请选择一台主机");
        return false;
      }
      let reaList = _.cloneDeep(this.groupManage.realEditList)
      let arr  = _.cloneDeep(this.selectedRows)
      this.selectedRows.map(item => {
        let key = item.id
        const index =  reaList.findIndex(record => record.id === key)
        if (index > -1) {
          reaList[index] = {
            ...reaList[index],
            activeKey: 'terminal',
            fromTo: 'ssh',
            fromToSshFirst: true,
            fromToFileFirst: true,
          }
          arr = arr.filter(child => child.id !== key)
        }
      })
      arr.map((record, index) => {
        let key = record.group_id + '-' + record.id
        let node = { isLeaf: false, dataRef: {...record, key: record.group_id + '-' + record.id, fullpath: '/',treeIconType: "host", treeType: "file", 
          isLeaf: false, isChild: false,
          index: reaList.length,
          tabselectKey: key,
          activeKey: 'terminal',
          fromTo: 'ssh',
          fromToSshFirst: true,
          fromToFileFirst: true,
          svgIcon: "group-host", parentArr: [{...record, name: "根目录", fullpath: '/', }]
        }}
        reaList.push({...node.dataRef})
      }) 
      this.changeRealEditList(reaList);
    },
    handleClickRow(record, index) {
      return {
        // style: {
        //   "background-color":
        //     index === this.setSelectedIndex ? "#e4eefd " : "#fff",
        // },
        on: {
          click: () => {
            this.setSelectedIndex = index;
            let key = [record.group_id + '-' + record.id]
            let node = { isLeaf: false, dataRef: {...record, key: record.group_id + '-' + record.id, fullpath: '/',treeIconType: "host", treeType: "file", 
              isLeaf: false, isChild: false,
              activeKey: 'dash-board',
              svgIcon: "group-host", parentArr: [{...record, name: "根目录", fullpath: '/', }]
            }}
            this.onSelectFileTree(key, node, 'host');
          },
        },
      };
    },
    clearSelectIndex() {
      this.setSelectedIndex = -1;
    },
    syncFile(fileList) {
      const selcttargetList = this.selectedRows;
      const selectRow = selcttargetList.filter((item) => !item.dest);
      // if (selectRow.length > 0) {
      //   that.$refs.bottomOptRef.syncLoading = false
      //   return this.$message.warning("请完善目标文件中的同步目录");
      // }
      let syncs = [];
      fileList.forEach((val) => {
        let data = {
          src_type: "file",
          dest_type: "dir",
          tgt_type: "list",
        };
        selcttargetList.map((item) => {
          syncs.push({
            ...data,
            dest: item.dest,
            src: '/' + val.name,
            // tgt: this.isHost ? item.id + "" : item.nodename,
            tgt: item.id + "",
            client: this.execute,
            replace: this.replace,
            makedirs: this.makedirs,
          });
        });
      });
      let data = {
        client: this.client,
        replace: this.replace,
        makedirs: this.makedirs,
        syncs,
      };
      const that = this;
      setTimeout(() => {
        that.$refs.bottomOptRef.syncLoading = false
      }, 1500);
      this.$axiosJsonPost(global.API.quickFileSync + '?cluster_id=' + this.groupManage.clusterId, data).then((res) => {
        if ([200, 201, 204].includes(res.status)) {
          this.$refs.bottomOptRef.clearFileList()
          that.$message.success("提交成功");
          that.setRunningFlag(true);
          that.setRunTab("file");
          that.setNumAdd(true);
        }
      });
    },
    // 运行命令
    runCommond() {
      if (this.selectedRows.length == 0) {
        this.$message.warning("请选择一条数据");
        return false;
      }
      var ids = [];
      this.selectedRows.map((item) => {
        ids.push(item.id + "");
      });
      this.drawerVisible = true
      this.isFullScreen = false
    },
    // 运行命令
    confirm(runObj) {
      let tgt = [];
      let order = [];
      let args = [];
      if (this.isHost && this.selectedRows.length > 0 && !this.isAll) {
        this.selectedRows.forEach((item) => {
          if(this.execute === 'ssh') {
            tgt.push(item.id + '');
          }  else {
            tgt.push(item.id + '');
          }
        });
      } else if (!this.isHost && this.selectedRows.length > 0 && !this.isAll) {
        this.selectedRows.forEach((item) => {
          tgt.push(item.nodename);
        });
      } else if (this.category && this.isAll) {
        order.push(this.category + "");
      } else {
        this.$message.warning("请至少选择一台主机");
        return;
      }
      for (let key in this.scriptParams) {
        args.push(this.scriptParams[key]);
      }
      let params = {
        client: this.execute,
        tgt_type: this.isAll
          ? this.isHost
            ? "manage_group"
            : "manage_target"
          : "list",
        tgt: this.isAll ? order : tgt,
        model: {
          lang: runObj.type || 'bash',
          code: runObj.code,
          timeout: 10,
          args: [],
        },
      };
      console.log(params);
      this.$refs.bottomOptRef.runLoading = true
      const that = this
      this.$EventBus.$emit('runCommand', this.selectedRowKeys, runObj.code)
      setTimeout(() => {
        this.$refs.bottomOptRef.runLoading = false
      }, 1500)
      this.$axiosJsonPost(
        global.API.syncNow + "?save2task=true&async=true" + '&cluster_id=' + this.groupManage.clusterId,
        params
      ).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
          this.setRunningFlag(true)
          this.$message.success("提交成功");
          let type = ''
          if (this.$refs.bottomOptRef.optTab === 'file') {
            type = 'file'
          } else {
            type = this.$refs.bottomOptRef.currentTab
          }
          if (type === 'script') type = 'cmd'
          this.setRunTab(type)
          // 刷新列表 把分页置位1
          this.setNumAdd(true)
        }
      });
    },
    handlerVars(vars) {
      let str = "";
      vars.map((item) => {
        let name = item["name"] ? item["name"] + ":  " : "";
        let value = item["value"] ? item["value"] + ",   " : "";
        str += name + value;
      });
      return str.slice(0, str.length - 4) || '-'
    },
    delHost() {
      const self = this;
      if (this.selectedRows.length == 0) {
        this.$message.warning("请选择一条数据");
        return false;
      }
      var ids = [];
      this.selectedRows.map((item) => {
        ids.push(item.id);
      });
      let width = 400;
      if (this.timer) clearTimeout(this.timer);
      this.noRequest = true
      let content = (
        <DeleteHost
          sysTypeTxt="主机"
          detail={{ ids: ids }}
          type="host"
          dataSource={this.dataSource}
          clusterId={this.groupManage.clusterId}
          pollingSearch={(dataSource) => {
            self.noRequest = false
            self.pollingSearch(dataSource)
          }}
          refreshList={() => {
            self.noRequest = false
            self.convertTreeData()
            self.onSearch();
            self.selectedRowKeys = [];
            self.selectedRows = [];
          }}
        />
      );
      this.$confirm({
        width: width,
        title: () => {
          return (
            <div>
              <a-icon
                type="question-circle"
                style="color:#2F7FD1 !important;margin-right:10px"
              />
              提示
            </div>
          );
        },
        content,
        onCancel: () => {
          this.noRequest = false
          // this.pollingSearch(this.dataSource)
        },
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    tableChange(pagination, filter, sorter) {
      const { order, columnKey } = sorter;
      console.log(order, columnKey);
      if (this.pagination.current !== pagination.current) {
        this.pagination.current = pagination.current;
      }
      if (order) {
        if (order === "ascend") {
          this.orderBy = columnKey;
        } else {
          this.orderBy = "-" + columnKey;
        }
      } else {
        this.orderBy = "";
      }
      this.getHostList();
    },
    changeTableDest(value, key, column) {
      const newData = [...this.dataSource]
      const target = newData.find((item) => key === item.id);
      if (target) {
        target[column] = value;
        target.editable = false
        this.setTable(newData)
      }
    },
    setTable (data) {
      this.dataSource = data
    },
    edit(key) {
      const newData = [...this.dataSource]
      const target = newData.find((item) => key === item.id);
      if (target) {
        target.editable = true;
        this.setTable(newData)
      }
    },
    handleChange(value) {
      this.isBlur = false
      let result = value != null && value != "" ? value : undefined;
      this.dest = result;
      let tableData = this.dataSource
      tableData.forEach((child) => {
        const serviceArr = child.vars ? child.vars.filter((item) => item.name === this.dest): []
        let serviceValue = "/";
        if (serviceArr.length > 0) {
          serviceValue = serviceArr[0].value;
          child.dest = serviceValue;
        } else {
          child.dest = value
        }
        child.editable = false;
      });
      this.setTable(tableData)
    },
    handleSelect(value) {
      this.dest = value;
      let tableData = this.dataSource
      tableData.forEach((child) => {
        const serviceArr = child.vars ? child.vars.filter((item) => item.name === this.dest): []
        let serviceValue = "/";
        if (serviceArr.length > 0) {
          serviceValue = serviceArr[0].value;
          child.dest = serviceValue;
        } else {
          child.dest = serviceValue
        }
        child.editable = false;
      });
      this.setTable(tableData)
    },
    getVal(val, filed) {
      this.params[`${filed}`] = val.target.value;
    },
    //   查询
    onSearch(selected) {
      if (this.timer) clearTimeout(this.timer);
      this.noRequest = true
      this.pagination.current = 1;
      this.getHostList(selected);
    },
    updateTabs (ids, vars) {
      const list = _.cloneDeep(this.groupManage.realEditList);
      list.forEach(item => {
        if (item.svgIcon === 'group-host' && ids.includes(item.id)) {
          item.vars = vars
        }
      })
      //记录当前打开页面
      console.log(list, 'hostList')
      this.changeRealEditList(list);
    },
    batchService() {
      if (this.selectedRows.length == 0) {
        this.$message.warning("请选择一条数据");
        return false;
      }
      var ids = [];
      this.selectedRows.map((item) => {
        ids.push(item.id);
      });
      const self = this;
      let width = 560;
      let title = "添加服务";
      let content = (
        <BatchServices
          ids={ids}
          clusterId={this.groupManage.clusterId}
          callBack={(vars) => {
            self.convertTreeData()
            self.updateTabs(ids, vars)
            self.onSearch();
            self.selectedRowKeys = [];
            self.selectedRows = [];
          }}
        />
      );
      this.$confirm({
        width: width,
        title: title,
        content: content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    batchLabel() {
      const self = this;
      if (this.selectedRows.length == 0) {
        this.$message.warning("请选择一条数据");
        return false;
      }
      var ids = [];
      this.selectedRows.map((item) => {
        ids.push(item.id);
      });
      let width = 560;
      let title = "添加标签";
      if (this.timer) clearTimeout(this.timer);
      this.noRequest = true
      let content = (
        <BatchLabel
          ids={ids}
          dataSource={this.dataSource}
          clusterId={this.groupManage.clusterId}
          pollingSearch={(dataSource) => {
            self.noRequest = false
            {/* self.pollingSearch(dataSource) */}
          }}
          callBack={() => {
            self.noRequest = false
            self.onSearch();
            self.selectedRowKeys = [];
            self.selectedRows = [];
          }}
        />
      );
      this.$confirm({
        width: width,
        title: title,
        content: content,
        closable: true,
        onCancel: () => {
          this.noRequest = false
          // this.pollingSearch(this.dataSource)
        },
        icon: () => {
          return <div />;
        },
      });
    },
    uploadHost() {
      const self = this;
      let width = 560;
      let title = "导入";
      let content = (
        <UploadFile
          clusterId={this.groupManage.clusterId}
          callBack={() => {
            self.onSearch();
            self.$emit("getGroupList");
          }}
        />
      );
      this.$confirm({
        width: width,
        title: title,
        content: content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    importHost() {
      const self = this;
      let width = 1081;
      let title = "";
      let content = (
        <HostEmpty
          style="height: 650px"
          clusterId={this.groupManage.clusterId}
          hasMaster={this.hasMaster}
          hostGroupId={this.groupManage.showItem.id}
          creatMaster={() => {
            self.onSearch();
            self.$emit("getGroupList");
            self.creatMaster();
          }}
        />
      );
      this.$confirm({
        width: width,
        class: "host-empty-dialog",
        title: title,
        content: content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    createHost(obj, e) {
      if (e) e.stopPropagation();
      const self = this;
      let width = 630;
      let title = JSON.stringify(obj) === "{}" ? "添加主机" : "编辑";
      if (this.timer) clearTimeout(this.timer);
      this.noRequest = true
      let content = (
        <AddHost
          hostGroupId={this.groupManage.showItem.id}
          detail={obj}
          dataSource={this.dataSource}
          clusterId={this.groupManage.clusterId}
          pollingSearch={(dataSource) => {
            self.noRequest = false
            {/* self.pollingSearch(dataSource) */}
          }}
          clearSelectIndex={() => self.clearSelectIndex()}
          hasMaster={self.hasMaster}
          callBack={() => {
            self.noRequest = false
            self.getHostList();
            self.convertTreeData()
          }}
        />
      );
      this.$confirm({
        width: width,
        title: title,
        content: content,
        closable: true,
        onCancel: () => {
          self.clearSelectIndex();
          this.noRequest = false
          // this.pollingSearch(this.dataSource)
        },
        icon: () => {
          return <div />;
        },
      });
    },
    deleteHost(obj, e) {
      if (e) e.stopPropagation();
      const self = this;
      let width = 400;
      if (this.timer) clearTimeout(this.timer);
      this.noRequest = true
      let content = (
        <DeleteHost
          sysTypeTxt="主机"
          detail={obj}
          dataSource={this.dataSource}
          clusterId={this.groupManage.clusterId}
          pollingSearch={(dataSource) => {
            self.noRequest = false
            {/* self.pollingSearch(dataSource) */}
          }}
          type="host"
          refreshList={() => {
            self.noRequest = false
            self.getHostList()
            self.convertTreeData()
          }}
        />
      );
      this.$confirm({
        width: width,
        title: () => {
          return (
            <div>
              <a-icon
                type="question-circle"
                style="color:#2F7FD1 !important;margin-right:10px"
              />
              提示
            </div>
          );
        },
        content,
        closable: true,
        onCancel: () => {
          this.noRequest = false
          // this.pollingSearch(this.dataSource)
        },
        icon: () => {
          return <div />;
        },
      });
    },
    getHostList(selected) {
      if (this.timer) clearTimeout(this.timer)
      if (!this.groupManage.clusterId) return false
      this.groupLoading = true;
      const params = {
        page_size: 1000,
        page: 1,
        keyword: this.params.name || "",
        order_by: this.orderBy,
        cluster_id: this.groupManage.clusterId
      };
      // 初始化的时候获取所有主机展示
      const showItemKey = this.groupManage.showItem.key
      let apiAjax = showItemKey.split("group")[1] === 'AllHost' ? global.API.getHostList : global.API.getHostGroups + `/${showItemKey.split("group")[1]}/hosts`
      this.$axiosGet(apiAjax, params).then((res) => {
        this.groupLoading = false;
        this.noRequest = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          const dataSource = res.data.results ? res.data.results : []
          this.handleAddHost(dataSource)
          if (this.dataSource.length) this.pollingSearch(this.dataSource)
          if (selected === 'selected') {
            this.params.name = ''
            this.selectedRows = this.dataSource
            this.selectedRowKeys = this.dataSource.map(item => item.id)
          }
        }
      });
    },
    // 执行之后定时去刷新列表，直到没有执行中的任务
    pollingSearch(dataSource) {
      let self = this;
      if (this.showItemKey !== this.groupManage.showItem.key) return clearTimeout(self.timer);
      if (self.timer) clearTimeout(self.timer);
      const ids = dataSource.map(item => item.id)
      this.$axiosGet(global.API.getMonitorByIds(ids.join(',')), {cluster_id: this.groupManage.clusterId}).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
          const monitorData = res.data.data
          for (var i in monitorData) {
            const index = dataSource.findIndex(item => item.id === i)
            if (index > -1) dataSource[index] = { ...dataSource[index], memory: parseFloat(monitorData[i].memory).toFixed(2), cpu: parseFloat(monitorData[i].cpu).toFixed(2), disk: monitorData[i].disk, uptime: monitorData[i].uptime.includes(":") ? monitorData[i].uptime : monitorData[i].uptime + '天', load: monitorData[i].load }
          }
          this.dataSource = [...dataSource]
          // if (!this.noRequest) this.dataSource = [...dataSource]
          // if (this.showItemKey === this.groupManage.showItem.key && !this.noRequest) {
          //   self.timer = setTimeout(() => {
          //     self.pollingSearch(this.dataSource)
          //   }, 5000);
          // } else {
          //   clearTimeout(self.timer)
          // }
        }
      });
    },
    // 确定添加主机
    handleAddHost(val) {
      const tableData = val.map((value, index) => {
        let serviceValue = "/";
        const serviceArr = value.vars ? value.vars.filter((item) => item.name === this.dest) : []
        if (this.dest) {
          serviceValue = this.dest
        } else {
          if (serviceArr.length > 0) {
            serviceValue = serviceArr[0].value;
          }
        }
        return {
          dest: serviceValue,
          editable: false,
          ...value,
        };
      });
      let arr = []
      this.dataSource = tableData
    },
    // 获取目标路劲list
    getHostServiceList() {
      this.$axiosGet(global.API.getHostServiceList, {}).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
          this.serviceList = res.data.filter((item) => item.name !== "")
          this.serviceList.map(item => {
            this.labelList.push(item.name)
          })
        }
      });
    },
  },
  mounted() {
    const showItemKey = this.groupManage.showItem.key
    this.showItemKey  = showItemKey
    const group =  showItemKey.split("group")
    if (group.length > 1 && group[1] && this.groupManage.showItem.treeType !== 'file') {
      this.getHostServiceList()
      this.getHostList()
    }
  },
};
</script>

  <style lang="less" scoped>
.host-list {
  /deep/ .ant-card-body {
    padding: 12px 0px 0px 20px;
  }
  height: calc(100vh - 84px);
  background: #F7F8FA;
  display: flex;
  flex-direction: column;
  .table-card {
    box-shadow: none;
    border: none;
    flex: 1;
    overflow-y: auto;
    .table-info {
      position: relative;
      .opt {
        position: absolute;
        bottom: 16px;
        left: 0;
        z-index: 10;
      }
      /deep/ .ant-table {
        max-height: calc(100vh - 456px);
        overflow-y: auto;
        padding-right: 20px;
      }
      /deep/ .ant-table-pagination {
        padding-right: 20px;
      }
    }
  }
  .btn-opt {
    border-radius: 2px;
    font-size: 12px;
    color: #0264c8;
    letter-spacing: 0;
    font-weight: 400;
  }
  .btn-fixed {
    position: fixed;
    right: 20px;
    top: 56px;
  }
  .green-tag {
    color: white;
    width: 44px;
    display: inline-block;
    background: #34C759;
    font-size: 12px;
    border-radius: 4px;
    padding: 2px 0 1px 0;
    text-align: center;
  }
  .yellow-tag {
    color: white;
    width: 44px;
    display: inline-block;
    background: #f0be42;
    font-size: 12px;
    padding: 2px 0 1px 0;
    border-radius: 4px;
    text-align: center;
  }
  .red-tag {
    color: white;
    width: 44px;
    display: inline-block;
    background: #FF4D4F;
    font-size: 12px;
    padding: 2px 0 1px 0;
    border-radius: 4px;
    text-align: center;
  }
}
/deep/.ant-input-affix-wrapper {
  input {
    height: 32px;
  }
}
/deep/.ant-input {
  border-radius: 4px;
}
/deep/.ant-card-body {
  padding-top: 12px;
  // display: flex;
  // justify-content: flex-end;
}
/deep/.w252 {
  margin-right: 0;
}
/deep/.ant-btn {
  height: 30px;
}
.host-footer {
  background: #fff;
  // height: 300px;
  padding: 0 20px;
}
</style>
  